import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Use useParams
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";
import { useDispatch } from "react-redux";
import { resetPasswordThunk } from "../store/thunks/authThunk";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"; // Import icons

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // Toggle for first password
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // Toggle for confirm password
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [token, setToken] = useState("");

  const { token: urlToken } = useParams(); // Extract token from URL path
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (urlToken) {
      setToken(urlToken);
    } else {
      setError("Invalid reset link. No token provided.");
    }
  }, [urlToken]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    if (!token) {
      setError("Missing token");
      return;
    }

    try {
      await dispatch(resetPasswordThunk({ token, password })).unwrap();
      setSuccess("Password reset successfully. Redirecting to login...");
      setTimeout(() => navigate("/login"), 3000);
    } catch (err) {
      setError(err.message || "Failed to reset password");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div>
          <img
            className="mx-auto h-12 w-auto cursor-pointer"
            src={logo}
            alt="Synergy Real Estate Logo"
            width={48}
            height={48}
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-[#0A2342]">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please enter your new password below.
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {/* New Password Input */}
          <div className="relative">
            <label htmlFor="password" className="sr-only">
              New Password
            </label>
            <input
              id="password"
              name="password"
              type={passwordVisible ? "text" : "password"}
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* Toggle Icon */}
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}>
              {passwordVisible ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" />
              )}
            </div>
          </div>

          {/* Confirm Password Input */}
          <div className="relative">
            <label htmlFor="confirmPassword" className="sr-only">
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type={confirmPasswordVisible ? "text" : "password"}
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {/* Toggle Icon */}
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}>
              {confirmPasswordVisible ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" />
              )}
            </div>
          </div>

          {error && <p className="text-center text-sm text-red-600">{error}</p>}
          {success && (
            <p className="text-center text-sm text-green-600">{success}</p>
          )}

          <div>
            <motion.button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342]"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Reset Password
            </motion.button>
          </div>
        </form>

        <div className="text-center">
          <motion.button
            onClick={() => navigate("/login")}
            className="text-sm text-[#0A2342] hover:text-[#1C4B82]"
            whileHover={{ scale: 1.05 }}>
            Back to login
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default ResetPasswordPage;
