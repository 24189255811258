import React, { useEffect } from "react";
import { XMarkIcon, LinkIcon, ShareIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";

const ShareModal = ({ property, onClose }) => {
  const dispatch = useDispatch();
  const currentUrl = window.location.href;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    dispatch(
      sendUserActivityLog({
        eventType: "SHARE_MODAL_OPENED",
        metadata: {
          mlsNumber: property.mlsNumber,
          boardId: property.boardId,
        },
        address: property.address,
      })
    );
  }, [dispatch]);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(currentUrl);
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy link:", err);
    }
  };

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Check out this property`,
          text: `Check out this ${property.type.toLowerCase()} at ${
            property.address.streetNumber
          } ${property.address.streetName}${
            property.address.streetSuffix &&
            property.address.streetSuffix !== "N/A"
              ? ` ${property.address.streetSuffix}`
              : ""
          }`,
          url: currentUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      handleSendText();
    }
  };

  const handleSendText = () => {
    const message = `Check out this property: ${currentUrl}`;
    window.location.href = `sms:?&body=${encodeURIComponent(message)}`;
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
      onClick={handleBackdropClick}>
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white p-6 max-w-md w-full mx-4 shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-[#0A2342]">
            Share Property
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <div className="space-y-4">
          <button
            onClick={handleCopyLink}
            className="w-full flex items-center justify-center gap-2 bg-[#0A2342] text-white py-2 px-4  hover:opacity-90 transition-opacity">
            <LinkIcon className="h-5 w-5" />
            Copy Link
          </button>

          {isMobile && (
            <button
              onClick={handleNativeShare}
              className="w-full flex items-center justify-center gap-2 bg-[#0A2342] text-white py-2 px-4 hover:opacity-90 transition-opacity">
              <ShareIcon className="h-5 w-5" />
              Share
            </button>
          )}
        </div>

        <div className="mt-4 text-sm text-gray-500">
          Share this {property.type.toLowerCase()} at{" "}
          {property.address.streetNumber} {property.address.streetName}
          {property.address.streetSuffix &&
          property.address.streetSuffix !== "N/A"
            ? ` ${property.address.streetSuffix}`
            : ""}
        </div>
      </motion.div>
    </div>
  );
};

export default ShareModal;
