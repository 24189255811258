import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import PropertyGridTile from "./PropertyGridTile";
import {
  fetchPersonalizedPropertiesThunk,
  fetchGeneralPropertiesThunk,
  fetchPersonalizedSoldThunk,
  fetchPersonalizedListThunk,
} from "../../store/thunks/repliersThunk";
import { fetchAllFavoritesThunk } from "../../store/thunks/repliersFavoritesThunk";
import LoadingSpinner from "../LoadingSpinner";
import {
  InformationCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  Squares2X2Icon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";

const PropertyCarousel = ({
  title,
  description,
  pageSize,
  fetchType,
  showing,
  hidePagination,
  boardId,
  properties: passedProperties = null, // For scenarios where data is passed directly
}) => {
  const dispatch = useDispatch();
  const [properties, setProperties] = useState(passedProperties || []);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isGridView, setIsGridView] = useState(false); // Toggle state for grid/carousel view

  const clientId = useSelector((state) => state.auth.user?.clientId);
  const [favorites, setFavorites] = useState([]); // Store favorites here

  // Determine if the screen is mobile-sized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsGridView(true); // Default to grid view on mobile
      }
    };

    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch favorites ONCE on mount
  useEffect(() => {
    refreshFavorites();
  }, [dispatch, clientId]);

  const refreshFavorites = async () => {
    if (clientId) {
      try {
        const updatedFavorites = await dispatch(
          fetchAllFavoritesThunk(clientId)
        ).unwrap();
        setFavorites(updatedFavorites); // ✅ Update favorites state
      } catch (error) {
        console.error("Error refreshing favorites:", error);
      }
    }
  };

  useEffect(() => {
    if (fetchType) {
      const fetchData = async () => {
        setLoading(true);
        try {
          let result;
          if (fetchType === "personalized") {
            result = await dispatch(
              fetchPersonalizedPropertiesThunk({ pageNum: currentPage })
            ).unwrap();
          } else if (fetchType === "general") {
            result = await dispatch(
              fetchGeneralPropertiesThunk({ pageNum: currentPage })
            ).unwrap();
          } else if (fetchType === "personalizedSold") {
            result = await dispatch(
              fetchPersonalizedSoldThunk({ pageNum: currentPage })
            ).unwrap();
          } else if (fetchType === "personalizedList") {
            result = await dispatch(
              fetchPersonalizedListThunk({ pageNum: currentPage })
            ).unwrap();
          }

          setProperties(result.listings || []);
          setCount(result.count || 0);
          setTotalPages(result.numPages || 1);
        } catch (error) {
          console.error(`Error fetching ${fetchType} properties:`, error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [fetchType, currentPage, dispatch]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <motion.section
      className="my-6"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      {title && (
        <div className="flex items-center justify-between mb-4 pl-4 relative">
          <div className="flex items-start">
            <h2 className="text-xl sm:text-2xl font-bold text-[#0A2342] flex items-center">
              {title}
              {count > 0 && ` [${count}]`}
              {description && (
                <button
                  className="text-blue-500 ml-2"
                  onClick={() => setShowTooltip(!showTooltip)}>
                  <InformationCircleIcon className="w-5 h-5 text-[#0A2342]" />
                </button>
              )}
            </h2>
            {showTooltip && (
              <div className="text-sm absolute bottom-full mb-1 w-80 bg-white border border-gray-300 p-2 text-[#0A2342] shadow-lg z-10">
                {description}
              </div>
            )}
          </div>
          {/* Hide toggle button on mobile */}
          <div className="hidden md:block">
            <button
              className={`flex items-center px-4 py-2 text-sm font-medium ${
                isGridView
                  ? "bg-[#0A2342] text-white"
                  : "bg-gray-200 text-[#0A2342]"
              } border border-[#0A2342] transition-all duration-200`}
              onClick={() => setIsGridView((prev) => !prev)}>
              {isGridView ? (
                <>
                  <ViewColumnsIcon className="w-5 h-5 mr-2" />
                  View
                </>
              ) : (
                <>
                  <Squares2X2Icon className="w-5 h-5 mr-2" />
                  View
                </>
              )}
            </button>
          </div>
        </div>
      )}

      <div className="container mx-auto">
        {loading ? (
          <div className="text-center">
            <LoadingSpinner title={"Loading properties..."} />
          </div>
        ) : (
          <div
            className={`${
              isGridView
                ? "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4"
                : "flex flex-nowrap overflow-x-auto space-x-2 px-4"
            }`}>
            {properties.length > 0 ? (
              properties.map((property) => (
                <div
                  className={`${
                    isGridView
                      ? ""
                      : "w-full sm:w-1/2 md:w-1/3 lg:w-1/5 flex-shrink-0 "
                  }  py-1 px-1`}
                  key={property.mlsNumber}>
                  <PropertyGridTile
                    property={property}
                    boardId={boardId}
                    favorites={favorites}
                    refreshFavorites={refreshFavorites}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-600 text-center">
                No properties available. Please try again later.
              </p>
            )}
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4 px-4">
          {!hidePagination && (
            <button
              className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}>
              <ArrowLeftIcon className="w-4 h-4" />
              <span className="text-sm">Previous</span>
            </button>
          )}
          <div className={"flex-1 text-center"}>
            <span className="text-sm text-[#0A2342]">
              Page {currentPage} of {totalPages} •{" "}
              {pageSize ? `Showing ${pageSize}` : `Showing ${showing}`}
            </span>
          </div>
          {!hidePagination && (
            <button
              className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}>
              <span className="text-sm">Next</span>
              <ArrowRightIcon className="w-4 h-4" />
            </button>
          )}
        </div>
      </div>
    </motion.section>
  );
};

export default PropertyCarousel;
