// thunks/emailThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const sendContactEmailThunk = createAsyncThunk(
  "email/sendContact",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.post(
        "/v1/api/email/send-contact",
        formData,
        config
      );

      return response.data;
    } catch (error) {
      console.error("Email sending error:", error.response || error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to send email"
      );
    }
  }
);

export const sendPropertyContactEmailThunk = createAsyncThunk(
  "email/sendPropertyContact",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      console.log("Property contact form data:", formData);

      const response = await api.post(
        "/v1/api/email/send-property-contact",
        formData,
        config
      );

      return response.data;
    } catch (error) {
      console.error("Property contact email error:", error.response || error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to send property inquiry"
      );
    }
  }
);

export const sendPasswordResetEmailThunk = createAsyncThunk(
  "email/sendPasswordReset",
  async (email, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/v1/api/email/send-password-reset",
        { email }
        // No config parameter here since we don't need authentication
      );

      return response.data;
    } catch (error) {
      console.error("Password reset email error:", error.response || error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to send password reset email"
      );
    }
  }
);
