import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRecommendationsThunk } from "../../store/thunks/recommenderThunk";

const RecommenderTest = ({ mlsNumber, boardId }) => {
  const dispatch = useDispatch();

  const [recommendations, setRecommendations] = useState([]);
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecommendations = async () => {
      if (!mlsNumber) return;

      setStatus("loading");
      setError(null);

      try {
        const result = await dispatch(
          fetchRecommendationsThunk({ mlsNumber, boardId })
        ).unwrap();

        // Ensure response structure matches expectations
        if (result && Array.isArray(result.recommendedListings)) {
          setRecommendations(result.recommendedListings);
        } else {
          setRecommendations([]);
        }

        setStatus("succeeded");
      } catch (err) {
        console.error("Error fetching recommendations:", err);
        setError(err || "Failed to fetch recommendations");
        setStatus("failed");
      }
    };

    fetchRecommendations();
  }, [mlsNumber, dispatch]);

  if (status === "loading") return <p>Loading...</p>;
  if (status === "failed") return <p>Error: {error}</p>;

  return (
    <div>
      <h3>Recommendations for MLS #{mlsNumber}</h3>
      <ul>
        {recommendations.length > 0 ? (
          recommendations.map((rec) => (
            <li key={rec.mlsNumber}>
              MLS Number: {rec.mlsNumber} — Match: {rec.matchPercentage}%
            </li>
          ))
        ) : (
          <li>No recommendations found.</li>
        )}
      </ul>
    </div>
  );
};

export default RecommenderTest;
