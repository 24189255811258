import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchImageThunk } from "../../store/thunks/repliersThunk";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import { motion } from "framer-motion";

import ImageCarousel from "./ImageCarousel";
import AddToFavoritesModal from "../favorites/AddToFavoritesModal";
import RemoveFromFavoritesModal from "../favorites/RemoveFromFavoritesModal";
import noImage from "../../assets/noimage.png";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon } from "@heroicons/react/24/outline";

const PropertyGridTile = ({
  property,
  boardId = null,
  favorites = [],
  refreshFavorites,
}) => {
  const dispatch = useDispatch();
  const [fetchedImages, setFetchedImages] = useState([]);
  const [isInFavorites, setIsInFavorites] = useState(false);
  const [favoriteId, setFavoriteId] = useState(null);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const isSold = property?.soldPrice && property?.soldDate;

  useEffect(() => {
    const matchedFavorite = favorites.find(
      (fav) => fav.mlsNumber === property.mlsNumber
    );
    setIsInFavorites(matchedFavorite);
    setFavoriteId(matchedFavorite?.favoriteId || null);
  }, [favorites, property.mlsNumber]);

  const handleHeartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isInFavorites) {
      setShowRemoveModal(true);
    } else {
      setShowFavoritesModal(true);
    }
  };

  const handleFavoriteAdded = () => {
    setShowFavoritesModal(false);
    refreshFavorites();
  };

  const handleFavoriteRemoved = () => {
    setShowRemoveModal(false);
    refreshFavorites();
  };

  useEffect(() => {
    if (property && property.images) {
      const fetchFirstImage = async () => {
        try {
          const firstImage = await dispatch(
            fetchImageThunk(property.images[0])
          ).unwrap();
          setFetchedImages([firstImage]);
        } catch (error) {
          // console.error("Failed to fetch the first image:", error);
        }
      };
      fetchFirstImage();
    }
  }, [property, dispatch]);

  const createSlug = (address) => {
    if (!address) return "";
    const { streetNumber, streetName, city, neighborhood } = address;
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean)
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleClick = (event) => {
    dispatch(
      sendUserActivityLog({
        eventType: "LISTING_CLICK",
        metadata: { mlsNumber: property.mlsNumber, boardId: property.boardId },
        address: property.address,
      })
    );

    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: "Property Grid Tile",
        event_label: "Clicked",
      });
    }

    const slug = createSlug(property.address);
    const url = `/propertydetails/${property.mlsNumber}/${
      property.boardId || boardId
    }/${slug}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  // DONT DELETE THIS - FOR PERMISSIONS
  const renderPermissionOverlay = () => {
    if (property.permissions.displayInternetEntireListing === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-red-500 bg-opacity-90 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">
            Not available for display. displayInternetEntireListing is N
          </p>
        </motion.div>
      );
    } else if (property.permissions.displayAddressOnInternet === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">
            displayAddressOnInternet is N
          </p>
        </motion.div>
      );
    } else if (property.permissions.displayPublic === "N") {
      return (
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-20 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <p className="text-white font-bold text-lg">Login required</p>
        </motion.div>
      );
    }
    return null;
  };

  if (!property) {
    return (
      <div>
        <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
          Gathering property info...
        </h2>
      </div>
    );
  }

  return (
    <>
      <motion.div
        className={`overflow-hidden cursor-pointer shadow-md border border-[#0A2342] md:w-full w-10/12 mx-auto relative`}
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.2 }}
        onClick={handleClick}>
        {/* {renderPermissionOverlay()} */}
        {/* Property Image and Tags */}
        <div className="relative">
          {isSold ? (
            <div className="z-10 absolute top-2 left-2 bg-[#0A2342] text-white text-xs font-semibold px-2 py-1">
              {(() => {
                const daysAgo = Math.floor(
                  (new Date() - new Date(property.soldDate)) /
                    (1000 * 60 * 60 * 24)
                );
                return daysAgo >= 0
                  ? `${daysAgo} days ago`
                  : new Date(property.soldDate).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    });
              })()}
            </div>
          ) : (
            <motion.div
              className="z-10 absolute top-2 left-2 cursor-pointer"
              whileHover={{ scale: 1.2 }}>
              <button
                onClick={handleHeartClick}
                className="absolute z-10 bg-opacity-80 
                   transition-all duration-200 hover:scale-110">
                {isInFavorites ? (
                  <HeartIcon className="h-6 w-6 text-[#fff] fill-[#0A2342]" />
                ) : (
                  <HeartIcon className="h-6 w-6 text-[#0A2342] fill-[#fff]" />
                )}
              </button>
            </motion.div>
          )}

          <div className="z-10 absolute top-2 right-2 cursor-pointer bg-[#0A2342] text-white text-xs font-semibold px-2 py-1">
            {isSold
              ? `${
                  property.distance !== null && property.distance !== undefined
                    ? `${property.distance.toFixed(2)} km away`
                    : `DOM: ${
                        property.daysOnMarket !== null &&
                        property.daysOnMarket !== undefined
                          ? property.daysOnMarket
                          : property.listDate
                          ? Math.floor(
                              (new Date() - new Date(property.listDate)) /
                                (1000 * 60 * 60 * 24)
                            )
                          : ""
                      }`
                }`
              : `DOM${property.listDate ? "" : ""}: ${
                  property.daysOnMarket !== null &&
                  property.daysOnMarket !== undefined
                    ? property.daysOnMarket
                    : property.listDate
                    ? Math.floor(
                        (new Date() - new Date(property.listDate)) /
                          (1000 * 60 * 60 * 24)
                      )
                    : ""
                }`}
          </div>

          {/* Conditional rendering based on property.images */}
          {property.images?.length > 0 ? (
            <ImageCarousel imageUrls={property.images} isGridTile={true} />
          ) : (
            <img
              src={noImage}
              alt="No Image Available"
              className="w-full h-full object-cover aspect-[4/3]"
            />
          )}
        </div>

        {/* Property Details */}
        <div className="p-2">
          {/* Property Price */}
          <div className="text-center text-white text-md font-bold px-1 py-1">
            {isSold ? (
              <div className="flex flex-col border-4 border-[#0A2342] bg-white justify-center items-center">
                <p className="text-lg font-bold text-[#0A2342] overflow-auto mt-1 mb-0.5 whitespace-normal">
                  SOLD - ${parseFloat(property.soldPrice).toLocaleString()}
                </p>
                {property.listPrice &&
                  (() => {
                    const soldPrice = parseFloat(property.soldPrice);
                    const listPrice = parseFloat(property.listPrice);
                    const differencePercentage = Math.round(
                      ((soldPrice - listPrice) / listPrice) * 100
                    );

                    if (differencePercentage > 0) {
                      return (
                        <div className="flex items-center mb-1.5">
                          <ArrowTrendingUpIcon
                            className="h-5 w-5 text-green-500 mr-1"
                            style={{
                              transform: "scale(1.2)",
                              filter: "drop-shadow(0px 0px 1px currentColor)",
                            }}
                            strokeWidth={3}
                          />{" "}
                          <p className="text-xs text-green-500 font-bold">
                            {`${differencePercentage}% over asking`}
                          </p>
                        </div>
                      );
                    } else if (differencePercentage < 0) {
                      return (
                        <div className="flex items-center mb-1.5">
                          <ArrowTrendingDownIcon
                            className="h-5 w-5 text-red-500 mr-1"
                            style={{
                              transform: "scale(1.2)",
                              filter: "drop-shadow(0px 0px 1px currentColor)",
                            }}
                            strokeWidth={3}
                          />{" "}
                          <p className="text-xs text-red-500 font-bold">
                            {`${Math.abs(differencePercentage)}% under asking`}
                          </p>
                        </div>
                      );
                    }

                    return null;
                  })()}
              </div>
            ) : (
              <div className="flex border-4 border-[#0A2342] justify-center items-center overflow-x-auto">
                <p className="flex items-center align-center text-lg font-bold text-[#0A2342] whitespace-normal px-2 py-0.5">
                  {property.listPrice
                    ? `$${parseFloat(property.listPrice).toLocaleString()}`
                    : "Price unavailable"}{" "}
                  {property.originalPrice &&
                    parseFloat(property.originalPrice) !==
                      parseFloat(property.listPrice) && (
                      <p className="text-sm text-gray-400 line-through ml-2">
                        ${parseFloat(property.originalPrice).toLocaleString()}
                      </p>
                    )}
                </p>
              </div>
            )}
          </div>
          {/* Property Address */}
          <div className="overflow-hidden">
            <p className="whitespace-nowrap text-md font-semibold text-[#0A2342] mt-1 overflow-hidden text-ellipsis">
              {property.address.streetNumber && property.address.streetName
                ? `${
                    property.address.unitNumber
                      ? `${property.address.unitNumber} - `
                      : ""
                  }${property.address.streetNumber} ${
                    property.address.streetName
                  }${
                    property.address.streetSuffix &&
                    property.address.streetSuffix !== "N/A"
                      ? ` ${property.address.streetSuffix}`
                      : ""
                  }`
                : "Address unavailable"}
              {property.address.city ? `, ${property.address.city}` : ""}
            </p>
          </div>

          {/* Property Type */}
          <div className="truncate overflow-hidden">
            <h3 className="whitespace-nowrap text-sm text-gray-600 mt-1 overflow-hidden text-ellipsis">
              {property.details.propertyType
                ? property.details.propertyType === "Att/Row/Townhouse"
                  ? "Attached"
                  : property.details.propertyType
                : "Property type unavailable"}{" "}
              {property.address.neighborhood
                ? `- ${property.address.neighborhood}`
                : ""}
            </h3>
          </div>

          {(property.details.numBedrooms !== null ||
            property.details.numBathrooms !== null ||
            property.details.sqft !== null) && (
            <div className="flex justify-between mt-1">
              <p className="text-sm text-gray-600 truncate overflow-hidden whitespace-nowrap">
                {property.details.numBedrooms || 0} Beds •{" "}
                {property.details.numBathrooms || 0} Baths{" "}
                {property.details.sqft ? `• ${property.details.sqft} sqft` : ""}
              </p>
            </div>
          )}

          {/* MLS Number and Brokerage Name */}
          {/* <div className="flex justify-between mt-1">
            <p className="text-[10px] text-gray-600 truncate overflow-hidden whitespace-nowrap">
              {property.mlsNumber
                ? `MLS® #${property.mlsNumber}`
                : "MLS# unavailable"}{" "}
            </p>
          </div> */}
        </div>
      </motion.div>

      {/* Favorites Modals */}
      {showFavoritesModal && (
        <AddToFavoritesModal
          property={property}
          onClose={handleFavoriteAdded}
        />
      )}
      {showRemoveModal && favoriteId && (
        <RemoveFromFavoritesModal
          property={property}
          favoriteId={favoriteId}
          onClose={handleFavoriteRemoved}
        />
      )}
    </>
  );
};

export default PropertyGridTile;
