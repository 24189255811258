import { createSlice } from "@reduxjs/toolkit";
import { fetchNlpPropertiesThunk } from "../thunks/repliersNlpThunk";

const initialState = {
  nlpSearchResults: [],
  nlpSearchSummary: "",
  nlpId: null,
  nlpStatus: "idle",
  nlpError: null,
};

const repliersNlpSlice = createSlice({
  name: "repliersNlp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch
      .addCase(fetchNlpPropertiesThunk.pending, (state) => {
        state.nlpStatus = "loading";
        state.nlpError = null;
      })
      .addCase(fetchNlpPropertiesThunk.fulfilled, (state, action) => {
        state.nlpStatus = "succeeded";
        state.nlpSearchResults = action.payload.listings;
        state.nlpSearchSummary = action.payload.summary;
        state.nlpId = action.payload.nlpId;
      })
      .addCase(fetchNlpPropertiesThunk.rejected, (state, action) => {
        state.nlpStatus = "failed";
        state.nlpError = action.payload || action.error.message;
      });
  },
});

export default repliersNlpSlice.reducer;
