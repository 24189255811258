import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAggregatesThunk,
  fetchGeneralPropertiesThunk,
  fetchPersonalizedPropertiesThunk,
  fullTextSearchThunk,
  fetchMarketStatisticsThunk,
  fetchSimilarPropertiesThunk,
  fetchSingleListingThunk,
  fetchImageThunk,
  fetchGeospatialListingsThunk,
  fetchClusterListingsThunk,
  fetchLocalDataThunk,
} from "../thunks/repliersThunk";

const dataSlice = createSlice({
  name: "repliers",
  initialState: {
    aggregatesStatus: "",
    aggregatesError: "",
    aggregates: [],

    propertyData: [],

    personalizedPropertyData: [],
    personalizedPropertyStatus: "idle",
    personalizedPropertyError: null,

    fullTextSearchResults: [],
    fullTextSearchStatus: null,
    fullTextSearchError: null,

    marketStatistics: [],
    marketStatisticsStatus: "idle",
    marketStatisticsError: null,

    singleListingData: [],

    similarProperties: { similar: [] },

    landingPageStatus: "idle",
    landingPageError: null,

    propertyDataStatus: "idle",
    propertyDataError: null,

    images: {},
    imageStatus: "idle",
    imageError: null,

    singleListingStatus: "idle",
    singleListingError: null,

    similarPropertiesStatus: "idle",
    similarPropertiesError: null,

    geospatialStatus: "",
    geospatialError: "",
    geospatialData: [],

    clusterStatus: "",
    clusterError: "",
    clusterData: [],
  },
  reducers: {
    // You can add synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAggregatesThunk.pending, (state) => {
        state.aggregatesStatus = "loading";
        state.aggregatesError = null;
      })
      .addCase(fetchAggregatesThunk.fulfilled, (state, action) => {
        state.aggregatesStatus = "succeeded";
        state.aggregates = action.payload;
      })
      .addCase(fetchAggregatesThunk.rejected, (state, action) => {
        state.aggregatesStatus = "failed";
        state.aggregatesError = action.payload || action.error.message;
      })

      // Handle fetchGeneralPropertiesThunk Thunk
      .addCase(fetchGeneralPropertiesThunk.pending, (state) => {
        state.propertyDataStatus = "loading";
        state.propertyDataError = null;
      })
      .addCase(fetchGeneralPropertiesThunk.fulfilled, (state, action) => {
        state.propertyDataStatus = "succeeded";
        state.propertyData = action.payload;
      })
      .addCase(fetchGeneralPropertiesThunk.rejected, (state, action) => {
        state.propertyDataStatus = "failed";
        state.propertyDataError = action.payload || action.error.message;
      })

      // Handle fetchPersonalizedPropertiesThunk Thunk
      .addCase(fetchPersonalizedPropertiesThunk.pending, (state) => {
        state.personalizedPropertyStatus = "loading";
        state.personalizedPropertyError = null;
      })
      .addCase(fetchPersonalizedPropertiesThunk.fulfilled, (state, action) => {
        state.personalizedPropertyStatus = "succeeded";
        state.personalizedPropertyData = action.payload;
      })
      .addCase(fetchPersonalizedPropertiesThunk.rejected, (state, action) => {
        state.personalizedPropertyStatus = "failed";
        state.personalizedPropertyError =
          action.payload || action.error.message;
      })

      // Handle fullTextSearchThunk Thunk
      .addCase(fullTextSearchThunk.pending, (state) => {
        state.fullTextSearchStatus = "loading";
        state.fullTextSearchError = null;
      })
      .addCase(fullTextSearchThunk.fulfilled, (state, action) => {
        state.fullTextSearchStatus = "succeeded";
        state.fullTextSearchResults = action.payload;
      })
      .addCase(fullTextSearchThunk.rejected, (state, action) => {
        state.fullTextSearchStatus = "failed";
        state.fullTextSearchError = action.payload || action.error.message;
      })

      // Handle fetchMarketStatisticsThunk Thunk
      .addCase(fetchMarketStatisticsThunk.pending, (state) => {
        state.marketStatisticsStatus = "loading";
        state.marketStatisticsError = null;
      })
      .addCase(fetchMarketStatisticsThunk.fulfilled, (state, action) => {
        state.marketStatisticsStatus = "succeeded";
        state.marketStatistics = action.payload;
      })
      .addCase(fetchMarketStatisticsThunk.rejected, (state, action) => {
        state.marketStatisticsStatus = "failed";
        state.marketStatisticsError = action.payload || action.error.message;
      })

      // Handle fetchSimilarPropertiesThunk Thunk
      .addCase(fetchSimilarPropertiesThunk.pending, (state) => {
        state.similarProperties = { similar: [] };
      })
      .addCase(fetchSimilarPropertiesThunk.fulfilled, (state, action) => {
        state.similarProperties = action.payload;
      })
      .addCase(fetchSimilarPropertiesThunk.rejected, (state, action) => {
        state.similarProperties = { similar: [] };
      })

      // Handle fetchSingleListingThunk Thunk
      .addCase(fetchSingleListingThunk.pending, (state) => {
        state.singleListingStatus = "loading";
        state.singleListingError = null;
      })
      .addCase(fetchSingleListingThunk.fulfilled, (state, action) => {
        state.singleListingStatus = "succeeded";
        state.singleListingData = action.payload;
      })

      // Handle fetchImageThunk Thunk
      .addCase(fetchImageThunk.pending, (state) => {
        state.imageStatus = "loading";
        state.imageError = null;
      })
      .addCase(fetchImageThunk.fulfilled, (state, action) => {
        state.imageStatus = "succeeded";
        if (!state.images) {
          state.images = {};
        }
        state.images[action.meta.arg] = action.payload;
      })
      .addCase(fetchImageThunk.rejected, (state, action) => {
        state.imageStatus = "failed";
        state.imageError = action.payload || action.error.message;
      })

      .addCase(fetchLocalDataThunk.pending, (state) => {
        state.coordinatesStatus = "loading";
        state.coordinatesError = null;
      })
      .addCase(fetchLocalDataThunk.fulfilled, (state, action) => {
        state.coordinatesStatus = "succeeded";
        state.coordinatesData = action.payload;
      })
      .addCase(fetchLocalDataThunk.rejected, (state, action) => {
        state.coordinatesStatus = "failed";
        state.coordinatesError = action.payload || action.error.message;
      })

      // Add this to the extraReducers section
      .addCase(fetchGeospatialListingsThunk.pending, (state) => {
        state.geospatialStatus = "loading";
        state.geospatialError = null;
      })
      .addCase(fetchGeospatialListingsThunk.fulfilled, (state, action) => {
        state.geospatialStatus = "succeeded";
        state.geospatialData = action.payload;
      })
      .addCase(fetchGeospatialListingsThunk.rejected, (state, action) => {
        state.geospatialStatus = "failed";
        state.geospatialError = action.payload || action.error.message;
      })

      .addCase(fetchClusterListingsThunk.pending, (state) => {
        state.clusterStatus = "loading";
        state.clusterError = null;
      })
      .addCase(fetchClusterListingsThunk.fulfilled, (state, action) => {
        state.clusterStatus = "succeeded";
        state.clusterData = action.payload;
      })
      .addCase(fetchClusterListingsThunk.rejected, (state, action) => {
        state.clusterStatus = "failed";
        state.clusterError = action.payload || action.error.message;
      });
  },
});

export default dataSlice.reducer;
