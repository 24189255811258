import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import PropertyGridTile from "../properties/PropertyGridTile";
import LoadingSpinner from "../LoadingSpinner";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { fetchAllFavoritesThunk } from "../../store/thunks/repliersFavoritesThunk";

const PropertiesGrid = ({
  title,
  properties,
  description,
  hidePagination = false,
  fetchProperties, // Pass a function to fetch data
}) => {
  const dispatch = useDispatch();
  const geospatialData = useSelector((state) => state.repliers.geospatialData);
  const clientId = useSelector((state) => state.auth.user?.clientId);
  const [favorites, setFavorites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(18); // Default values

  // Fetch favorites ONCE on mount
  useEffect(() => {
    refreshFavorites();
  }, [dispatch, clientId]);

  const refreshFavorites = async () => {
    if (clientId) {
      try {
        const updatedFavorites = await dispatch(
          fetchAllFavoritesThunk(clientId)
        ).unwrap();
        setFavorites(updatedFavorites); // ✅ Update favorites state
      } catch (error) {
        console.error("Error refreshing favorites:", error);
      }
    }
  };

  useEffect(() => {
    // Fetch data on mount with default pagination
    fetchProperties(currentPage, resultsPerPage);
  }, []); // Empty dependency array to run on mount

  // Sync currentPage with backend `geospatialData.page` on mount and updates
  useEffect(() => {
    if (geospatialData.page && geospatialData.page !== currentPage) {
      setCurrentPage(geospatialData.page);
    }
  }, [geospatialData.page]);

  const handleNextPage = () => {
    if (currentPage < geospatialData.numPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      fetchProperties(newPage, resultsPerPage);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      fetchProperties(newPage, resultsPerPage);
    }
  };

  const handleResultsPerPageChange = (e) => {
    const newResultsPerPage = parseInt(e.target.value, 10);
    setResultsPerPage(newResultsPerPage);
    setCurrentPage(1); // Reset to the first page
    fetchProperties(1, newResultsPerPage);
  };

  return (
    <motion.section
      className="flex flex-col h-screen"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      {/* Header with sticky top */}
      <div className="px-4 py-2 border-gray-500 flex flex-col md:flex-row justify-between items-center bg-white sticky top-0 z-20 shadow-sm">
        <h1 className="text-lg font-semibold text-[#0A2342] mb-2 md:mb-0">
          {geospatialData.count} available
        </h1>
        {!hidePagination && (
          <div className="flex gap-4 md:flex-row items-center">
            <button
              className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50 md:mb-0"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}>
              <ArrowLeftIcon className="w-4 h-4" />
              <span className="text-sm">Previous</span>
            </button>
            <div className="flex items-center md:mb-0">
              <span className="text-sm text-[#0A2342]">
                Page {geospatialData.page || currentPage} of{" "}
                {geospatialData.numPages}
              </span>
            </div>
            <button
              className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50 md:mb-0"
              onClick={handleNextPage}
              disabled={currentPage === geospatialData.numPages}>
              <span className="text-sm">Next</span>
              <ArrowRightIcon className="w-4 h-4" />
            </button>
            <div className="flex items-center md:mb-0">
              <select
                className="text-sm border border-gray-300 p-2 rounded focus:outline-none"
                value={resultsPerPage}
                onChange={handleResultsPerPageChange}>
                <option value={18}>View 18</option>
                <option value={30}>View 30</option>
                <option value={42}>View 42</option>
              </select>
            </div>
          </div>
        )}
      </div>

      {/* Property Grid */}
      <div className="flex-grow overflow-y-auto">
        <div className="pt-4 px-4 container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            {properties.length > 0 ? (
              properties.map((property) => (
                <PropertyGridTile
                  key={property.mlsNumber}
                  property={property}
                  favorites={favorites} // Pass favorites as a prop
                  refreshFavorites={refreshFavorites}
                />
              ))
            ) : (
              <p className="text-gray-600">
                <LoadingSpinner title={"Fetching properties in your area"} />
              </p>
            )}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default PropertiesGrid;
