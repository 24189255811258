import React from "react";
import { motion } from "framer-motion";
import NLPSearchBar from "./search/NLPSearchBar";
import house10 from "../assets/house 10.png";

const FindYourDream = () => {
  return (
    <motion.div
      className="relative mb-12"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <img
        src={house10}
        alt="Featured property"
        className="w-full h-80 md:h-80 object-cover"
      />
      <div className="absolute inset-0 bg-[#0A2342] bg-opacity-70 flex flex-col justify-center items-center text-white p-6 py-16 md:p-12">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">
          Search for your dream home with Synergy AI
        </h2>
        <NLPSearchBar />
      </div>
    </motion.div>
  );
};

export default FindYourDream;
