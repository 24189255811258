import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRecentActivity } from "../../store/thunks/adminThunk";
import { format, formatDistanceToNow } from "date-fns";
import { formatAddress } from "../../utils/formatAddress";

const AdminRecentActivity = () => {
  const dispatch = useDispatch();
  const [recentActivity, setRecentActivity] = useState([]);

  useEffect(() => {
    dispatch(fetchRecentActivity())
      .then((data) => {
        setRecentActivity(data.payload); // Set recent activity state
      })
      .catch((error) => {
        console.error("Error fetching recent activity:", error);
      });
  }, [dispatch]);

  const calculateTimeSpent = (milliseconds) => {
    if (!milliseconds || typeof milliseconds !== "number") return "N/A";

    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `--- ${days} day${days > 1 ? "s" : ""}`;
    if (hours > 0) return `--- ${hours} hour${hours > 1 ? "s" : ""}`;
    if (minutes > 0) return `--- ${minutes} minute${minutes > 1 ? "s" : ""}`;
    return `--- ${seconds} second${seconds > 1 ? "s" : ""}`;
  };

  const handleActivityClick = (activity) => {
    let url;
    const baseUrl = window.location.origin;

    if (activity.metadata.page) {
      url = `${baseUrl}${activity.metadata.page}`;
    } else if (activity.metadata.mlsNumber && activity.metadata.boardId) {
      // Construct fallback URL for property details page
      url = `${baseUrl}/propertydetails/${activity.metadata.mlsNumber}/${activity.metadata.boardId}/`;
    } else {
      console.warn(
        "No valid URL or MLS details available for activity:",
        activity
      );
      return;
    }

    // Always open in a new tab
    window.open(url, "_blank");
  };

  return (
    <div className="mt-10">
      <h2 className="text-xl font-semibold text-gray-900">Recent Activity</h2>
      <div className="bg-white shadow-md mt-4 border border-gray-200 overflow-x-auto">
        <ul className="divide-y divide-gray-200">
          {recentActivity.length > 0 ? (
            recentActivity.map((activity) => (
              <li
                key={activity._id}
                className="p-4 flex justify-between items-center text-sm cursor-pointer hover:bg-gray-50"
                onClick={() => handleActivityClick(activity)}>
                <div className="w-full">
                  <span className="font-medium text-gray-900">
                    {activity.userDetails.firstName}{" "}
                    {activity.userDetails.lastName} (
                    {activity.userDetails.username}){" "}
                  </span>
                  <span className="text-gray-600">
                    {activity.eventType} on {activity.metadata.page}{" "}
                  </span>

                  {activity.address && (
                    <span className="text-gray-600">
                      {formatAddress(activity.address)}{" "}
                    </span>
                  )}

                  {/* Conditionally render the metadata based on the event type */}
                  {activity.eventType === "NLP_SEARCH_QUERY" && (
                    <span className="text-gray-600">
                      {activity.metadata.searchQuery}
                    </span>
                  )}

                  {activity.eventType === "ADD_TO_FAVORITES" && (
                    <span className="text-gray-600">
                      <strong>MLS Number:</strong> {activity.metadata.mlsNumber}{" "}
                      <strong> Tag:</strong> {activity.metadata.tag}{" "}
                      <strong> Comments:</strong> {activity.metadata.comments}
                    </span>
                  )}

                  {activity.eventType === "REMOVE_FROM_FAVORITES" && (
                    <span className="text-gray-600">
                      <strong>MLS Number:</strong> {activity.metadata.mlsNumber}
                    </span>
                  )}

                  {activity.eventType === "LISTING_CLICK" && (
                    <span className="text-gray-600">
                      <strong>MLS Number:</strong> {activity.metadata.mlsNumber}
                      {", "}
                      <strong>boardId:</strong> {activity.metadata.boardId}
                    </span>
                  )}

                  {activity.eventType === "TIME_SPENT_BEFORE_SCROLL" && (
                    <span className="text-gray-600">
                      {calculateTimeSpent(activity.metadata.timeSpent)}
                    </span>
                  )}

                  {activity.eventType === "TOTAL_TIME_SPENT" && (
                    <span className="text-gray-600">
                      {calculateTimeSpent(activity.metadata.timeSpent)}
                    </span>
                  )}

                  {activity.eventType === "LOCAL_PLACES_SECTION_VIEW" && (
                    <span className="text-gray-600">
                      <strong>Selected section:</strong>{" "}
                      {activity.metadata.selectedSection}
                    </span>
                  )}
                </div>

                <div className="flex flex-col items-end text-right">
                  <p className="text-xs text-gray-500 whitespace-nowrap">
                    {format(new Date(activity.timestamp), "MMM d yyyy, h:mm a")}
                  </p>
                  <p className="text-xs text-gray-500">
                    {formatDistanceToNow(new Date(activity.timestamp), {
                      addSuffix: true,
                    })}
                  </p>
                </div>
              </li>
            ))
          ) : (
            <li className="p-4 text-gray-500">No recent activity available.</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AdminRecentActivity;
