import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllFavoritesThunk,
  createFavoriteThunk,
} from "../../store/thunks/repliersFavoritesThunk";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk"; // Assuming you have this action

const AddToFavoritesModal = ({ property, onClose }) => {
  const dispatch = useDispatch();

  // Local states
  const [comments, setComments] = useState("");
  const [selectedTag, setSelectedTag] = useState(""); // The existing tag
  const [newTagName, setNewTagName] = useState(""); // The new tag
  const [isCreatingNewTag, setIsCreatingNewTag] = useState(false);

  // Pull in the user's clientId from auth state
  const clientId = useSelector((state) => state.auth.user?.clientId);

  // Pull all existing favorites
  const { favorites } = useSelector((state) => state.repliersFavorites);

  // Fetch all favorites on mount
  useEffect(() => {
    if (clientId) {
      dispatch(fetchAllFavoritesThunk(clientId));
    }
  }, [clientId, dispatch]);

  // Extract unique tags
  const allTags = Array.from(
    new Set(
      favorites
        .flatMap((fav) => fav.tags || [])
        .filter((tag) => tag && tag.trim() !== "")
    )
  );

  // Disable Add button if comments are empty or no tag is selected/created
  const isAddDisabled =
    !comments.trim() ||
    (isCreatingNewTag ? !newTagName.trim() : !selectedTag.trim());

  // Create or add a favorite
  const handleAddFavorite = async () => {
    if (!clientId) {
      toast.error("No clientId found. Please contact support.");
      return;
    }
    if (!property?.mlsNumber || !property?.boardId) {
      toast.error("Property is missing mlsNumber or boardId.");
      return;
    }

    // Determine the final tag to use
    const finalTag = isCreatingNewTag ? newTagName : selectedTag;

    // Log user activity
    dispatch(
      sendUserActivityLog({
        eventType: "ADD_TO_FAVORITES",
        metadata: {
          propertyTitle: property.title,
          mlsNumber: property.mlsNumber,
          boardId: property.boardId,
          tag: finalTag,
          comments,
        },
        address: property.address,
      })
    );

    // Prepare the payload for Repliers
    const payload = {
      clientId,
      boardId: Number(property.boardId),
      mlsNumber: property.mlsNumber,
      comments,
      tags: [finalTag],
    };

    try {
      await dispatch(createFavoriteThunk(payload)).unwrap();
      toast.success(`Added to ${finalTag}.`);
      onClose();
    } catch (error) {
      console.error("Error adding favorite to Repliers:", error);
      toast.error("Failed to add favorite.");
    }
  };

  // Stop modal click from closing
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 pointer-events-auto"
      onClick={onClose}>
      <div className="bg-white p-6 max-w-md w-full" onClick={handleModalClick}>
        <h2 className="text-2xl font-bold text-[#0A2342] mb-4">
          Add to Favorites
        </h2>

        {/* Comments Field */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Comments (Required)
          </label>
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="w-full p-2 border"
            placeholder="Any notes or comments?"
            required
          />
        </div>

        {isCreatingNewTag ? (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                New List Name (Required)
              </label>
              <input
                type="text"
                value={newTagName}
                onChange={(e) => setNewTagName(e.target.value)}
                className="w-full p-2 border"
                placeholder="e.g. 'My Condo Wishlist'"
                required
              />
            </div>
            <button
              onClick={() => {
                setIsCreatingNewTag(false);
                setNewTagName("");
              }}
              className="text-[#0A2342] text-sm mb-4 block">
              Use Existing List
            </button>
          </>
        ) : (
          <>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Existing List (Required)
            </label>
            <div className="relative mb-4">
              <select
                value={selectedTag}
                onChange={(e) => setSelectedTag(e.target.value)}
                className="block w-full appearance-none bg-white border border-gray-300 py-2 px-3 pr-10 text-sm leading-5 text-gray-900 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                required>
                <option value="">--Select a list--</option>
                {allTags.map((tag) => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none text-[#0A2342] absolute inset-y-0 right-0 flex items-center pr-3">
                <ChevronDownIcon className="h-5 w-5" />
              </div>
            </div>
            <button
              onClick={() => setIsCreatingNewTag(true)}
              className="text-[#0A2342] text-sm mb-4 block">
              Create New List
            </button>
          </>
        )}

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-[#0A2342] text-[#0A2342]">
            Cancel
          </button>
          <button
            onClick={handleAddFavorite}
            className={`px-4 py-2 text-white ${
              isAddDisabled
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-[#0A2342] hover:opacity-90"
            }`}
            disabled={isAddDisabled}>
            Add to Favorites
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToFavoritesModal;
