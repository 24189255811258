import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmailThunk } from "../../store/thunks/emailThunk";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const Security = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const handleResetPassword = async () => {
    try {
      await dispatch(sendPasswordResetEmailThunk(user.username)).unwrap();
      navigate("/loading", {
        state: {
          message: "Password reset email sent...",
          duration: 3000,
          redirectTo: "/settings",
        },
      });
    } catch (error) {
      console.error("Error sending reset email:", error);
    }
  };

  return (
    <div>
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Security Settings
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        Manage your security settings here.
      </p>
      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Password
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="text-gray-900">••••••••</div>
            <motion.button
              type="button"
              onClick={handleResetPassword}
              className="font-semibold text-[#0A2342] hover:text-gold-500"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Reset Password
            </motion.button>
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default Security; 