import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivityLogs } from "../../store/thunks/adminThunk";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend,
  Label,
} from "recharts";
import LoadingSpinner from "../LoadingSpinner";

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#ff6384"];

const ActivityLogChart = () => {
  const dispatch = useDispatch();
  const { activityChartData, deviceProportions, loading, error } = useSelector(
    (state) => state.admin
  );

  const [hours, setHours] = useState(null);
  useEffect(() => {
    if (hours !== null) {
      dispatch(fetchActivityLogs({ hours }))
        .unwrap()
        .catch((error) =>
          console.error("Error fetching activity logs:", error)
        );
    }
  }, [dispatch, hours]); // Runs only when `hours` changes

  // Format time for X-Axis
  // Format the timestamp to a readable date format: Thu Feb 8, 2025, 01:00AM
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month (01-12)
    const day = date.getDate().toString().padStart(2, "0"); // Day (01-31)
    const hours = date.getHours().toString().padStart(2, "0"); // Hours (00-23)
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Minutes (00-59)
    const ampm = hours >= 12 ? "PM" : "AM"; // AM/PM

    // Convert to 12-hour format
    const hour12 = hours % 12 || 12; // 12-hour format (1-12)

    return `${month}-${day} ${hour12}:${minutes} ${ampm}`; // Format as MM-DD HH:mm AM/PM
  };

  return (
    <div className="flex flex-col">
      {/* <h1 className="text-2xl font-semibold">Activity Log Chart</h1> */}

      {/* Time Range Selector */}
      <div className="my-4">
        <select
          value={hours ?? ""}
          onChange={(e) => setHours(e.target.value)}
          className="p-2 border rounded">
          <option value="" disabled>
            Select time range...
          </option>
          <option value={4}>Last 4 hours</option>
          <option value={8}>Last 8 hours</option>
          <option value={12}>Last 12 hours</option>
          <option value={24}>Last 24 hours</option>
          <option value={36}>Last 36 hours</option>
          <option value={48}>Last 48 hours</option>
          <option value={168}>Last 7 days</option>
        </select>
      </div>

      {hours === null ? (
        <p>Please select a time range to view activity logs.</p>
      ) : null}

      {loading && <LoadingSpinner />}
      {error && <p className="text-red-500">{error}</p>}

      {/* Main Chart Container (flex row) */}
      <div className="flex flex-wrap gap-8">
        {/* Line Chart */}
        {activityChartData.length > 0 && (
          <div className="mt-8 flex-1">
            <h2 className="text-xl font-semibold pb-4">
              Activity Logs - All Users
            </h2>
            <AreaChart width={800} height={400} data={activityChartData}>
              <CartesianGrid strokeDasharray="3 3" />

              {/* X-Axis with custom label */}
              <XAxis
                dataKey="time"
                tickFormatter={formatTime} // Apply custom time format
                tick={false} // Hide the ticks
                interval={3} // Show every 4th tick (if ticks are enabled later)
                angle={-45} // Rotate the X ticks diagonally
                textAnchor="end" // Align the ticks to the end after rotation
              >
                <Label
                  value="Date"
                  position="insideBottom"
                  style={{
                    textAnchor: "middle",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                />
              </XAxis>

              {/* Y-Axis with custom label */}
              <YAxis domain={["auto", "auto"]}>
                <Label
                  value="# Logs"
                  angle={-90}
                  position="insideLeft"
                  style={{
                    textAnchor: "middle",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                />
              </YAxis>

              <Tooltip labelFormatter={(label) => formatTime(label)} />

              <Area
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </div>
        )}

        {/* Pie Chart for Device Browser/OS */}
        {deviceProportions.length > 0 && (
          <div className="mt-8 flex-1">
            <h2 className="text-xl font-semibold">
              Device Browser & OS Breakdown
            </h2>
            <PieChart width={400} height={400}>
              <Pie
                data={deviceProportions}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={120}
                fill="#8884d8"
                dataKey="proportion"
                nameKey="type">
                {deviceProportions.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityLogChart;
