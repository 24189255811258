import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../store/thunks/adminThunk";
import AdminTable from "./AdminTable";
import UserPreferencesModal from "./UserPreferencesModal";
import Pagination from "./Pagination";
import LoadingSpinner from "../LoadingSpinner";

const AdminUsers = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [limit, setLimit] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    dispatch(fetchUsers({ page: currentPage, limit, sortBy, sortOrder }))
      .then((result) => {
        if (fetchUsers.fulfilled.match(result)) {
          setUsers(result.payload.users);
          setTotalUsers(result.payload.totalUsers);
          setTotalPages(result.payload.totalPages);
          setCurrentPage(result.payload.currentPage);
        } else {
          setError(result.error.message);
        }
      })
      .finally(() => setLoading(false));
  }, [dispatch, currentPage, limit, sortBy, sortOrder]);

  const handleSort = (column) => {
    setSortOrder(sortBy === column && sortOrder === "asc" ? "desc" : "asc");
    setSortBy(column);
  };

  const handlePageChange = (newPage) => {
    if (newPage !== currentPage && newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
  };

  const handleLimitChange = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };

  const columns = [
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
    { key: "username", label: "Username" },
    { key: "loginCount", label: "Login Count" },
    {
      key: "onboardingComplete",
      label: "Onboarding",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      key: "lastActive",
      label: "Last Active",
      render: (value) =>
        value
          ? new Date(value).toLocaleString("en-US", {
              year: "numeric",
              month: "short", // Ensures "Aug" instead of "8"
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })
          : "N/A", // Show "N/A" if lastActive is missing
    },
    { key: "activityLogCount", label: "Activity Log Count" },

    {
      key: "createdAt",
      label: "Created At",
      render: (value) =>
        new Date(value).toLocaleString("en-US", {
          year: "numeric",
          month: "short", // Ensures "Aug" instead of "8"
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        }),
    },
    { key: "userType", label: "User Type" },
  ];

  if (loading) return <LoadingSpinner />;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-4">
      <div className="">
        <AdminTable
          data={users}
          columns={columns}
          title={"Users"}
          description={"A list of all users on SynergyAI."}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={handleSort}
          onUserClick={handleUserClick}
          limit={limit}
          onLimitChange={handleLimitChange}
        />
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalUsers={totalUsers}
        limit={limit}
        onPageChange={handlePageChange}
      />

      {selectedUser && (
        <UserPreferencesModal user={selectedUser} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default AdminUsers;
