import React from "react";
import { useDispatch } from "react-redux";
import ImageCarousel from "./ImageCarousel";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import noImage from "../../assets/noimage.png";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/solid";

const PropertyRectangularCard = ({ property }) => {
  const dispatch = useDispatch();

  const isSold = property?.soldPrice && property?.soldDate;

  const createSlug = (address) => {
    if (!address) return "";

    const { streetNumber, streetName, city, neighborhood } = address;
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean)
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, ""); // Remove invalid characters
  };

  const handleCardClick = () => {
    if (!property || !property.address) {
      console.error("Property or address is undefined:", property);
      return;
    }

    dispatch(
      sendUserActivityLog({
        eventType: "LISTING_CLICK",
        metadata: { mlsNumber: property.mlsNumber, boardId: property.boardId },
        address: property.address,
      })
    );

    const slug = createSlug(property.address);
    const url = `/propertydetails/${property.mlsNumber}/${property.boardId}/${slug}`;
    window.open(url, "_blank");
  };

  return (
    <div
      onClick={handleCardClick}
      className="z-50 flex items-center border bg-white border-gray-300 shadow-md p-1.5 w-full max-w-lg cursor-pointer hover:bg-gray-100 transition-all duration-150 hover:scale-102"
      style={{ transition: "transform 0.15s ease-in-out" }}>
      <div className="w-2/3 pr-2 flex flex-col justify-between h-full">
        {/* Main Property Info */}
        <div>
          <div className="text-center text-white text-md font-bold px-1 py-1">
            {isSold ? (
              <div className="flex flex-col border-4 border-[#0A2342] bg-white justify-center items-center">
                <p className="text-lg my-1 font-bold text-[#0A2342] overflow-auto whitespace-normal">
                  SOLD - ${parseFloat(property.soldPrice).toLocaleString()}
                </p>
                {property.listPrice &&
                  (() => {
                    const soldPrice = parseFloat(property.soldPrice);
                    const listPrice = parseFloat(property.listPrice);
                    const differencePercentage = Math.round(
                      ((soldPrice - listPrice) / listPrice) * 100
                    );

                    if (differencePercentage > 0) {
                      return (
                        <div className="flex items-center mb-1 mb-2">
                          <ArrowTrendingUpIcon
                            className="h-5 w-5 text-green-500 mr-1"
                            style={{
                              transform: "scale(1.2)",
                              filter: "drop-shadow(0px 0px 1px currentColor)",
                            }}
                            strokeWidth={3}
                          />{" "}
                          <p className="text-xs text-green-500 font-bold">
                            {`${differencePercentage}% over asking`}
                          </p>
                        </div>
                      );
                    } else if (differencePercentage < 0) {
                      return (
                        <div className="flex items-center mb-1 mb-2">
                          <ArrowTrendingDownIcon
                            className="h-5 w-5 text-red-500 mr-1"
                            style={{
                              transform: "scale(1.2)",
                              filter: "drop-shadow(0px 0px 1px currentColor)",
                            }}
                            strokeWidth={3}
                          />{" "}
                          <p className="text-xs text-red-500 font-bold">
                            {`${Math.abs(differencePercentage)}% under asking`}
                          </p>
                        </div>
                      );
                    }

                    return null;
                  })()}
              </div>
            ) : (
              <div className="flex border-4 border-[#0A2342] justify-center items-center overflow-x-auto">
                <p className="flex items-center align-center text-lg font-bold text-[#0A2342] whitespace-normal px-2">
                  {property.listPrice
                    ? `$${parseFloat(property.listPrice).toLocaleString()}`
                    : "Price unavailable"}{" "}
                  {property.originalPrice &&
                    parseFloat(property.originalPrice) !==
                      parseFloat(property.listPrice) && (
                      <p className="text-sm text-gray-400 line-through ml-2">
                        ${parseFloat(property.originalPrice).toLocaleString()}
                      </p>
                    )}
                </p>
              </div>
            )}
          </div>
          <p className="text-sm font-semibold text-[#0A2342] mt-1">
            {property.address.unitNumber
              ? `${property.address.unitNumber} - `
              : ""}
            {property.address.streetNumber} {property.address.streetName}{" "}
            {property.address.streetSuffix}, {property.address.city}
          </p>
          <p className="text-sm text-gray-500 mt-1">
            {property.details.numBedrooms || 0} Beds •{" "}
            {property.details.numBathrooms || 0} Baths{" "}
            {property.details.sqft ? `• ${property.details.sqft} sqft` : ""}
          </p>
        </div>
      </div>

      <div className="w-1/3">
        <div className="flex space-x-4">
          {property.images?.length > 0 ? (
            <ImageCarousel imageUrls={property.images} isGridTile={true} />
          ) : (
            <img
              src={noImage}
              alt="No Image Available"
              className="w-full h-auto"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyRectangularCard;
