import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchNlpPropertiesThunk = createAsyncThunk(
  "data/fetchNlpProperties",
  async (prompt, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.post("/v1/api/nlp-search", { prompt }, config);

      console.log("Response from backend NLP search:", response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching NLP properties:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch NLP properties"
      );
    }
  }
);
