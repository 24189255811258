import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import {
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";
import { useMediaQuery } from "react-responsive";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/outline";

// Helper function to generate a list of months between two "YYYY-MM" strings
const generateMonthList = (start, end) => {
  const startDate = new Date(`${start}-01`);
  const endDate = new Date(`${end}-01`);
  const months = [];
  const current = new Date(startDate);

  while (
    current.getFullYear() < endDate.getFullYear() ||
    (current.getFullYear() === endDate.getFullYear() &&
      current.getMonth() <= endDate.getMonth())
  ) {
    const year = current.getFullYear();
    const month = (current.getMonth() + 1).toString().padStart(2, "0");
    months.push(`${year}-${month}`);
    current.setMonth(current.getMonth() + 1);
  }

  return months;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const listPrice =
      payload.find((item) => item.name === "List Price")?.value || 0;
    const soldPrice =
      payload.find((item) => item.name === "Sold Price")?.value || 0;
    const daysOnMarket =
      payload.find((item) => item.name === "Days on Market")?.value || 0;

    const delta =
      listPrice && soldPrice ? ((soldPrice - listPrice) / listPrice) * 100 : 0;
    const isPositive = delta >= 0;

    const dataItems = [
      {
        label: "List Price",
        value: listPrice === 0 ? "N/A" : `$${listPrice.toLocaleString()}`,
        color: "#0A2342",
      },
      {
        label: "Sold Price",
        value: soldPrice === 0 ? "N/A" : `$${soldPrice.toLocaleString()}`,
        color: "#E5B13A",
      },
      {
        label: "Days on Market",
        value: daysOnMarket === 0 ? "N/A" : daysOnMarket.toLocaleString(),
        color: "#0A2342",
      },
    ];

    return (
      <div className="bg-gray-100 border border-[#0A2342] p-3 shadow-md w-[200px]">
        <p className="text-gray-700 font-bold">
          {label
            ? new Date(label).toLocaleString("default", {
                month: "short",
                year: "numeric",
              })
            : "Invalid Date"}
        </p>
        {dataItems.map((item, index) => (
          <div key={index} className="flex items-center mt-2">
            <div
              className="h-2.5 w-2.5 rounded-sm"
              style={{ backgroundColor: item.color }}></div>
            <span className="ml-2 text-sm text-gray-700">{item.label}</span>
            <span className="ml-auto text-sm text-gray-800 font-medium">
              {item.value}
            </span>
          </div>
        ))}
        {listPrice !== 0 && soldPrice !== 0 && (
          <div className="mt-3 flex items-center border-t pt-2 text-sm text-[#0A2342] font-medium">
            List vs Sold:
            <div className="ml-auto flex items-center text-sm">
              <span className={isPositive ? "text-green-500" : "text-red-500"}>
                {`${Math.abs(delta).toFixed(1)}%`}
              </span>
              {isPositive ? (
                <ArrowTrendingUpIcon className="inline-block h-5 w-5 ml-1 text-green-500" />
              ) : (
                <ArrowTrendingDownIcon className="inline-block h-5 w-5 ml-1 text-red-500" />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

const MarketStatisticsChart = ({ property, marketStatistics }) => {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [selectedStats, setSelectedStats] = useState({
    listPrice: true,
    soldPrice: false,
    daysOnMarket: false,
  });

  if (!marketStatistics || !marketStatistics.statistics) {
    return <p>No market statistics available.</p>;
  }

  const { listPrice, soldPrice, daysOnMarket } = marketStatistics.statistics;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1

  const allMonthsSet = new Set([
    ...Object.keys(listPrice.mth),
    ...Object.keys(soldPrice.mth),
    ...Object.keys(daysOnMarket.mth),
  ]);

  const allMonths = Array.from(allMonthsSet).sort();

  if (allMonths.length === 0) {
    return <p>No market statistics available.</p>;
  }

  const earliestMonth = allMonths[0];
  const latestMonth = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}`;

  const completeMonthList = generateMonthList(earliestMonth, latestMonth);

  // Initialize previous values
  let prevListPrice = 0;
  let prevSoldPrice = 0;
  let prevDOM = 0;

  const chartData = completeMonthList.map((month) => {
    const listPriceData = listPrice.mth[month];
    const soldPriceData = soldPrice.mth[month];
    const domData = daysOnMarket.mth[month];

    // Update previous values if data exists and count > 0
    if (listPriceData && listPriceData.count > 0) {
      prevListPrice = listPriceData.avg;
    }
    if (soldPriceData && soldPriceData.count > 0) {
      prevSoldPrice = soldPriceData.avg;
    }
    if (domData && domData.count > 0) {
      // Added for Days on Market
      prevDOM = domData.avg;
    }

    return {
      month: new Date(`${month}-01`), // Convert to Date object for XAxis
      listPrice:
        listPriceData && listPriceData.count > 0
          ? listPriceData.avg
          : prevListPrice,
      soldPrice:
        soldPriceData && soldPriceData.count > 0
          ? soldPriceData.avg
          : prevSoldPrice,
      daysOnMarket: domData && domData.count > 0 ? domData.avg : prevDOM, // Updated to carry forward DOM
    };
  });

  const toggleStat = (stat) => {
    setSelectedStats((prev) => {
      const newStats = { ...prev, [stat]: !prev[stat] };

      // Define event types based on stat
      const eventTypeMap = {
        listPrice: "MARKET_STATS_AVG_LIST_CLICKED",
        soldPrice: "MARKET_STATS_AVG_SOLD_CLICKED",
        daysOnMarket: "MARKET_STATS_AVG_DOM_CLICKED",
      };

      // Dispatch activity log only if stat is being enabled (clicked ON)
      if (!prev[stat]) {
        dispatch(
          sendUserActivityLog({
            eventType: eventTypeMap[stat],
            metadata: {
              mlsNumber: property.mlsNumber,
              boardId: property.boardId,
            },
            address: property.address,
          })
        );
      }

      return newStats;
    });
  };

  return (
    <div
      className={`p-4 shadow-md ${
        isSmallScreen
          ? "flex-col justify-center items-center"
          : "flex-col justify-center p-4"
      }`}>
      <div className="mb-4">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="pb-4">
            <h2 className="text-xl font-semibold text-[#0A2342] mb-4 md:mb-0">
              List vs Sold Prices
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Showing average market trends for {property.details.numBedrooms}{" "}
              bedrooms {property.details.numBathrooms} bathrooms in{" "}
              {property.address.city}.
            </p>
          </div>
          <div className="flex border border-[#0A2342] p-2.5 flex-wrap justify-center gap-2.5 md:flex md:ml-auto shadow-md bg-gray-50">
            <button
              className={`flex flex-col items-center px-4 py-3 border transition duration-300 ${
                selectedStats.listPrice
                  ? "bg-[#0A2342] text-white border-[#0A2342] shadow-md"
                  : "bg-white text-gray-700 border-gray-300 hover:bg-blue-100 hover:border-[#0A2342]"
              }`}
              onClick={() => toggleStat("listPrice")}>
              <dt
                className={`text-sm font-semibold ${
                  selectedStats.listPrice ? "text-white" : "text-gray-700"
                }`}>
                Avg List
              </dt>
              <dd
                className={`text-xl font-bold ${
                  selectedStats.listPrice ? "text-white" : "text-[#0A2342]"
                }`}>
                ${listPrice.avg.toLocaleString()}
              </dd>
            </button>

            <button
              className={`flex flex-col items-center px-4 py-3 border transition duration-300 ${
                selectedStats.soldPrice
                  ? "bg-[#E5B13A] text-white border-[#E5B13A] shadow-md"
                  : "bg-white text-gray-700 border-gray-300 hover:bg-yellow-100 hover:border-[#E5B13A]"
              }`}
              onClick={() => toggleStat("soldPrice")}>
              <dt
                className={`text-sm font-semibold ${
                  selectedStats.soldPrice ? "text-white" : "text-gray-700"
                }`}>
                Avg Sold
              </dt>
              <dd
                className={`text-xl font-bold ${
                  selectedStats.soldPrice ? "text-white" : "text-[#0A2342]"
                }`}>
                ${soldPrice.avg.toLocaleString()}
              </dd>
            </button>

            <button
              className={`flex flex-col items-center px-4 py-3 border transition duration-300 ${
                selectedStats.daysOnMarket
                  ? "bg-[#0A2342] text-white border-[#0A2342] shadow-md"
                  : "bg-white text-gray-700 border-gray-300 hover:bg-blue-100 hover:border-[#0A2342]"
              }`}
              onClick={() => toggleStat("daysOnMarket")}>
              <dt
                className={`text-sm font-semibold ${
                  selectedStats.daysOnMarket ? "text-white" : "text-gray-700"
                }`}>
                Avg DOM
              </dt>
              <dd
                className={`text-xl font-bold ${
                  selectedStats.daysOnMarket ? "text-white" : "text-[#0A2342]"
                }`}>
                {daysOnMarket.avg} days
              </dd>
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <ResponsiveContainer
          width={isSmallScreen ? 300 : "100%"}
          height={isSmallScreen ? 400 : 400}
          className="flex justify-center">
          <ComposedChart
            data={chartData}
            margin={
              isSmallScreen
                ? { top: 0, right: -18, left: -18, bottom: 0 }
                : { top: 20, right: -10, left: 0, bottom: 10 }
            }>
            <XAxis
              dataKey="month"
              tickFormatter={(month, index) => {
                return index % 6 === 0
                  ? new Date(month).toLocaleString("default", {
                      month: "short",
                      year: "numeric",
                    })
                  : "";
              }}
              interval={0}
              axisLine={!isSmallScreen}
              tick={!isSmallScreen}
              tickLine={!isSmallScreen}
              label={
                !isSmallScreen ? { value: "", position: "insideBottom" } : null
              }
              hide={isSmallScreen}
            />

            <YAxis
              tickFormatter={(value) => {
                if (value === 0) {
                  return "";
                } else if (value >= 1000000) {
                  return `$${(value / 1000000).toFixed(1)}M`;
                } else if (value >= 1000) {
                  return `$${(value / 1000).toFixed(0)}k`;
                }
                return `$${value.toLocaleString()}`;
              }}
              axisLine={!isSmallScreen}
              tick={!isSmallScreen}
              tickLine={!isSmallScreen}
              hide={isSmallScreen}
            />

            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={(value) =>
                value > 0 ? `${value.toLocaleString()}d` : ""
              }
              axisLine={!isSmallScreen}
              tick={!isSmallScreen}
              tickLine={!isSmallScreen}
              hide={isSmallScreen}
            />

            <Tooltip content={<CustomTooltip />} />
            <Legend
              formatter={(value) => {
                if (value === "listPrice") return "List Price";
                if (value === "soldPrice") return "Sold Price";
                if (value === "daysOnMarket") return "Days on Market";
                return value;
              }}
              layout="horizontal"
              align="center"
            />
            {selectedStats.listPrice && (
              <Area
                type="monotone"
                dataKey="listPrice"
                stroke="#0A2342"
                fill="#0A2342"
                fillOpacity={0.6}
                name="List Price"
                connectNulls={true}
              />
            )}
            {selectedStats.soldPrice && (
              <Area
                type="monotone"
                dataKey="soldPrice"
                stroke="#E5B13A"
                fill="#E5B13A"
                fillOpacity={0.6}
                name="Sold Price"
                connectNulls={true}
              />
            )}
            {selectedStats.daysOnMarket && (
              <Bar
                yAxisId="right"
                dataKey="daysOnMarket"
                fill="#0A2342"
                name="Days on Market"
                barSize={8}
                connectNulls
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MarketStatisticsChart;
