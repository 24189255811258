import { createSlice } from "@reduxjs/toolkit";
import {
  checkAdminStatus,
  fetchUsers,
  fetchActivityLogs,
} from "../thunks/adminThunk";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isAdmin: false,
    users: [],
    activityChartData: [],
    deviceProportions: [],
    totalUsers: 0,
    totalPages: 0,
    currentPage: 1,
    loading: false,
    error: null,
    adminChecked: false,
    hasFetchedUsers: false,
    // userPreferences: null, // New state to hold user preferences
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAdminStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkAdminStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.isAdmin = action.payload;
      })
      .addCase(checkAdminStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // .addCase(fetchUsers.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchUsers.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.users = action.payload.users;
      //   state.totalUsers = action.payload.totalUsers;
      //   state.totalPages = action.payload.totalPages;
      //   state.currentPage = action.payload.currentPage;
      //   state.hasFetchedUsers = true;
      // })
      // .addCase(fetchUsers.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // .addCase(fetchUserPreferenceById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchUserPreferenceById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.userPreferences = action.payload; // Store the fetched user preferences
      // })
      // .addCase(fetchUserPreferenceById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      .addCase(fetchActivityLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActivityLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.activityChartData = action.payload.chartData;
        state.deviceProportions = action.payload.deviceProportions;
      })
      .addCase(fetchActivityLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage } = adminSlice.actions;
export default adminSlice.reducer;
