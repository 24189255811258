import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { fullTextSearchThunk } from "../../store/thunks/repliersThunk";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import PropertyRectangularCard from "../properties/PropertyRectangularCard";
import { motion } from "framer-motion";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import _ from "lodash";

const SearchBar = () => {
  const [input, setInput] = useState("");
  const [results, setResults] = useState({ listings: [], aggregates: {} });
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const searchBarRef = useRef(null);

  const fetchResults = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setResults({ listings: [], aggregates: {} });
      return;
    }

    dispatch(
      sendUserActivityLog({
        eventType: "SEARCH",
        metadata: { query: searchTerm },
      })
    );

    try {
      const response = await dispatch(
        fullTextSearchThunk({ search: searchTerm })
      ).unwrap();
      setResults(response);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const debouncedFetchResults = useRef(_.debounce(fetchResults, 300)).current;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    debouncedFetchResults(value);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={searchBarRef}
      className="z-40 relative bg-[#f5f5f5] border border-[#0A2342] shadow-md flex flex-col items-center w-full max-w-md mx-auto">
      <div className="flex w-full items-center">
        <input
          type="text"
          placeholder="Search by address, street, area, MLS..."
          className="flex-grow px-2 py-1 text-[#0A2342] focus:outline-none"
          value={input}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <button
          className="p-2 bg-[#0A2342] text-white"
          onClick={() => fetchResults(input)}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <MagnifyingGlassIcon className="h-5 w-5" />
          </motion.div>
        </button>
      </div>

      {showDropdown && (
        <div className="absolute top-full left-0 w-full px-2 py-1 bg-white border border-[#0A2342] shadow-sm z-10 max-h-96 overflow-y-auto">
          {input.trim() === "" ? (
            <div className="py-2 text-gray-500 text-sm">Start searching...</div>
          ) : results.listings.length > 0 ? (
            results.listings.map((listing) => (
              <div key={listing.mlsNumber} className="py-1">
                <PropertyRectangularCard property={listing} />
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500 text-sm">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
