import ActivityLogChart from "./ActivityLogChart";

const AdminActivity = () => {
  return (
    <div>
      <h1 className="text-2xl font-semibold">Activity Logs</h1>
      <ActivityLogChart />
    </div>
  );
};

export default AdminActivity;
