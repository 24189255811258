import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  fetchAllFavoritesThunk,
  deleteFavoriteThunk,
} from "../store/thunks/repliersFavoritesThunk";
import {
  PlusIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import PropertyRectangularCard from "../components/properties/PropertyRectangularCard";
import LoadingSpinner from "../components/LoadingSpinner";
import RemoveFromFavoritesModal from "../components/favorites/RemoveFromFavoritesModal";

const FavouritesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientId = useSelector((state) => state.auth.user?.clientId);
  const { favorites = [] } = useSelector((state) => state.repliersFavorites);

  const [selectedList, setSelectedList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;

  // NEW: State for Remove Modal
  const [selectedProperty, setSelectedProperty] = useState(null);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchAllFavoritesThunk(clientId));
    }
  }, [clientId, dispatch]);

  // Extract unique lists from favorites
  const allLists = Array.from(
    new Set(favorites.flatMap((fav) => fav.tags || []))
  );

  // Filter properties by selected list
  const selectedListProperties = favorites.filter((fav) =>
    selectedList ? fav.tags?.includes(selectedList) : false
  );

  // Pagination logic
  const totalPages = Math.ceil(selectedListProperties.length / pageSize);
  const paginatedProperties = selectedListProperties.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // Handle selecting a list
  const handleSelectList = (list) => {
    setSelectedList(list);
    setCurrentPage(1);
  };

  // NEW: Open Remove Modal
  const handleOpenRemoveModal = (property) => {
    setSelectedProperty(property);
  };

  // NEW: Close Remove Modal
  const handleCloseRemoveModal = () => {
    setSelectedProperty(null);
  };

  // Handle redirection to add a favorite
  const handleAddFavoriteRedirect = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-[#0A2342]">My Favorites</h1>
      </div>

      {/* Lists Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {allLists.length > 0 ? (
          allLists.map((list) => (
            <motion.div
              key={list}
              whileHover={{ scale: 1.02 }}
              className={`p-4 border cursor-pointer ${
                selectedList === list ? "bg-[#0A2342] text-white" : "bg-white"
              }`}
              onClick={() => handleSelectList(list)}>
              <div className="flex flex-col justify-between h-full">
                <h3 className="font-semibold">{list}</h3>
                <p className="text-sm mt-2">
                  {favorites.filter((fav) => fav.tags?.includes(list)).length}{" "}
                  properties
                </p>
              </div>
            </motion.div>
          ))
        ) : (
          <p className="text-gray-500">No lists available.</p>
        )}
      </div>

      {/* Message when no list is selected */}
      {!selectedList && (
        <div className="mt-8 p-4 border border-gray-300 text-center text-gray-600">
          Select a list to view favorite properties
        </div>
      )}

      {/* Selected List Content */}
      {selectedList && (
        <div className="mt-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">{selectedList}</h2>
            <button
              onClick={() => setSelectedList("")}
              className="text-[#0A2342] text-sm">
              Clear Selection
            </button>
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <LoadingSpinner />
            </div>
          ) : paginatedProperties.length === 0 ? (
            <div className="flex flex-col justify-center items-center h-64">
              <p className="text-gray-500">Nothing here... add a favourite</p>
              <button
                onClick={handleAddFavoriteRedirect}
                className="mt-4 px-4 py-2 bg-[#0A2342] text-white hover:bg-opacity-90">
                Add a Favourite
              </button>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {paginatedProperties.map((property) => (
                  <div key={property.mlsNumber} className="relative">
                    <PropertyRectangularCard property={property} />
                    <div className="py-2 flex justify-between">
                      {property.comments && (
                        <p className="text-sm text-gray-500">
                          Comments: {property.comments}
                        </p>
                      )}

                      {/* NEW: Open Remove Modal */}
                      <button
                        onClick={() => handleOpenRemoveModal(property)}
                        className="text-red-500 hover:text-red-700">
                        <p className="text-sm">Remove</p>
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination Controls */}
              <div className="flex justify-between items-center mt-8 px-4">
                <button
                  className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}>
                  <ArrowLeftIcon className="w-4 h-4" />
                  <span className="text-sm">Previous</span>
                </button>
                <span className="text-sm text-[#0A2342]">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}>
                  <span className="text-sm">Next</span>
                  <ArrowRightIcon className="w-4 h-4" />
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {/* NEW: Remove from Favorites Modal */}
      {selectedProperty && (
        <RemoveFromFavoritesModal
          property={selectedProperty}
          onClose={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default FavouritesPage;
