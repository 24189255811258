import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk"; // Import activity logging
import LocalPlaceCard from "./LocalPlaceCard";
import "./LocalPlaces.css";

const LocalPlaces = ({ property, localData, mapRef }) => {
  const [openLocalPlaces, setOpenLocalPlaces] = useState(true);
  const [activeSection, setActiveSection] = useState("schools");
  const dispatch = useDispatch();
  const sectionRefs = { local: useRef(null) };

  // // Add debug logging
  // useEffect(() => {
  //   console.log("LocalPlaces mapRef:", mapRef);
  //   console.log("LocalPlaces mapRef.current:", mapRef?.current);
  // }, [mapRef]);

  // Pass activeSection to Map component through mapRef
  useEffect(() => {
    if (mapRef?.current?.setActiveSection && typeof mapRef.current.setActiveSection === 'function') {
      mapRef.current.setActiveSection(activeSection);
    } else {
      console.warn('setActiveSection method not found on map reference');
    }
  }, [activeSection, mapRef]);

  const openGoogleMapsDirections = (propertyAddress, destinationAddress) => {
    if (!propertyAddress || !destinationAddress) return;

    const origin = `${propertyAddress.streetNumber} ${
      propertyAddress.streetName
    }${
      propertyAddress.streetSuffix && propertyAddress.streetSuffix !== "N/A"
        ? ` ${propertyAddress.streetSuffix}`
        : ""
    }, ${propertyAddress.city}, ${propertyAddress.state} ${
      propertyAddress.zip
    }`;

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
      origin
    )}&destination=${encodeURIComponent(destinationAddress)}`;

    window.open(googleMapsUrl, "_blank");
  };

  // Track section clicks
  const handleSectionClick = (section) => {
    setActiveSection(section);
    console.log("Selected section in LocalPlaces:", section);
    
    if (mapRef?.current?.setActiveSection && typeof mapRef.current.setActiveSection === 'function') {
      console.log("Calling setActiveSection on map ref");
      mapRef.current.setActiveSection(section);
    } else {
      console.warn("setActiveSection method not found on map reference");
    }

    // Send activity log
    dispatch(
      sendUserActivityLog({
        eventType: "LOCAL_PLACES_SECTION_VIEW",
        metadata: {
          selectedSection: section,
          mlsNumber: property.mlsNumber,
          boardId: property.boardId,
        },
        address: property.address,
      })
    );
  };

  return (
    <motion.div
      ref={sectionRefs.local}
      className="bg-white p-4"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <h2
        className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
        onClick={() => setOpenLocalPlaces(!openLocalPlaces)}>
        Local Places
        <ChevronDownIcon
          className={`h-6 w-6 ml-2 transform transition-transform ${
            openLocalPlaces ? "rotate-180" : "rotate-0"
          }`}
        />
      </h2>
      {openLocalPlaces && (
        <>
          <div className="flex overflow-x-auto space-x-4 mb-4">
            {[
              {
                key: "schools",
                label: `${localData?.schools?.length} Schools`,
              },
              { key: "parks", label: `${localData?.parks?.length} Parks` },
              {
                key: "safety_places",
                label: `${localData?.safety_places?.length} Safety Places`,
              },
              {
                key: "transit_stops",
                label: `${localData?.transit_stops?.length} Transit Stops`,
              },
            ].map(({ key, label }) => (
              <button
                key={key}
                onClick={() => handleSectionClick(key)} // Track section change
                className={`px-4 py-2 whitespace-nowrap ${
                  activeSection === key
                    ? "bg-[#0A2342] text-white"
                    : "bg-white text-[#0A2342] border border-[#0A2342]"
                }`}>
                {label}
              </button>
            ))}
          </div>

          {activeSection === "schools" && (
            <div className="grid grid-flow-col grid-rows-2 gap-4 overflow-x-auto">
              {localData.schools?.map((school, index) => (
                <LocalPlaceCard
                  key={index}
                  name={school.name}
                  type={school.type}
                  distance={school.distance}
                  address={school.features.Address}
                  score={school.score}
                  facilities={[
                    school.type?.join(", ") || "",
                    school.language?.join(", ") || "",
                    `Grades ${
                      school.grades_offered[0]
                    }-${school.grades_offered.at(-1)}`,
                  ]
                    .filter(Boolean)
                    .join(" • ")}
                  url={school.url}
                  onMapClick={() =>
                    openGoogleMapsDirections(
                      property?.address,
                      school.features.Address
                    )
                  }
                />
              ))}
            </div>
          )}

          {activeSection === "parks" && (
            <div className="grid grid-flow-col grid-rows-2 gap-4 overflow-x-auto">
              {localData.parks?.map((park, index) => (
                <LocalPlaceCard
                  key={index}
                  name={park.name}
                  distance={park.distance}
                  address={park.features.Address}
                  facilities={park.facilities.join(", ")}
                  url={park.url}
                  onMapClick={() =>
                    openGoogleMapsDirections(
                      property?.address,
                      park.features.Address
                    )
                  }
                />
              ))}
            </div>
          )}

          {activeSection === "safety_places" && (
            <div className="space-y-4">
              {localData.safety_places?.map((place, index) => (
                <LocalPlaceCard
                  key={index}
                  name={place.name}
                  type={place.type}
                  distance={place.distance}
                  address={place.features.Address}
                  url={place.url}
                  onMapClick={() =>
                    openGoogleMapsDirections(
                      property?.address,
                      place.features.Address
                    )
                  }
                />
              ))}
            </div>
          )}

          {activeSection === "transit_stops" && (
            <div className="grid grid-flow-col grid-rows-2 gap-4 overflow-x-auto">
              {localData.transit_stops?.map((stop, index) => (
                <LocalPlaceCard
                  key={index}
                  name={stop.name}
                  distance={stop.distance}
                  walking_time={stop.walking_time}
                  address={stop.features.Address}
                  url={stop.url}
                />
              ))}
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

export default LocalPlaces;