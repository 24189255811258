import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAdminStatus } from "../store/thunks/adminThunk";
import AdminOverview from "../components/adminPage/AdminOverview";
import AdminUsers from "../components/adminPage/AdminUsers";
import AdminActivity from "../components/adminPage/AdminActivity";
import AdminTrends from "../components/adminPage/AdminTrends";
import LoadingSpinner from "../components/LoadingSpinner";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogPanel } from "@headlessui/react";

const AdminPage = () => {
  const dispatch = useDispatch();
  const { isAdmin, loading, error, adminChecked } = useSelector(
    (state) => state.admin
  );
  const { token } = useSelector((state) => state.auth);

  const [selectedTab, setSelectedTab] = useState("overview");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (token && !adminChecked) {
      dispatch(checkAdminStatus(token));
    }
  }, [dispatch, token, adminChecked]);

  if (loading) {
    // return <p className="text-center text-gray-600 mt-16">Loading...</p>;
    return <LoadingSpinner />;
  }

  if (error) {
    return <p className="text-center text-red-500 mt-16">{error}</p>;
  }

  if (!isAdmin) {
    return (
      <p className="text-center text-gray-600 mt-16">
        You do not have access to this page.
      </p>
    );
  }

  const renderTabContent = () => {
    switch (selectedTab) {
      case "overview":
        return <AdminOverview />;
      case "users":
        return <AdminUsers />;
      case "activity":
        return <AdminActivity />;
      case "trends":
        return <AdminTrends />;
      case "listings":
        // return <AdminActivity />;
        return <p>all listings will be here</p>;
      default:
        return <p className="text-gray-700">This is the Overview component</p>;
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Top Navigation */}
      <div className=" bg-white border-b border-gray-200 shadow-sm">
        {" "}
        {/* Added `mt-16` to push below global Header */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-16">
          <button onClick={() => setMobileMenuOpen(true)} className="md:hidden">
            <Bars3Icon className="w-6 h-6 text-gray-900" />
          </button>
          <nav className="hidden md:flex space-x-6 text-gray-700 font-semibold">
            <button
              onClick={() => setSelectedTab("overview")}
              className={`px-4 py-2 ${
                selectedTab === "overview"
                  ? "bg-indigo-700 text-white"
                  : "hover:bg-gray-100"
              }`}>
              Overview
            </button>
            <button
              onClick={() => setSelectedTab("users")}
              className={`px-4 py-2 ${
                selectedTab === "users"
                  ? "bg-indigo-700 text-white"
                  : "hover:bg-gray-100"
              }`}>
              Users
            </button>
            <button
              onClick={() => setSelectedTab("activity")}
              className={`px-4 py-2 ${
                selectedTab === "activity"
                  ? "bg-indigo-700 text-white"
                  : "hover:bg-gray-100"
              }`}>
              Activity Logs
            </button>
            <button
              onClick={() => setSelectedTab("trends")}
              className={`px-4 py-2 ${
                selectedTab === "trends"
                  ? "bg-indigo-700 text-white"
                  : "hover:bg-gray-100"
              }`}>
              Trends
            </button>
            <button
              onClick={() => setSelectedTab("listings")}
              className={`px-4 py-2 ${
                selectedTab === "listings"
                  ? "bg-indigo-700 text-white"
                  : "hover:bg-gray-100"
              }`}>
              Active Listings
            </button>
          </nav>
        </div>
      </div>

      {/* Mobile Menu */}
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden">
        <DialogPanel className="fixed inset-y-0 left-0 z-50 w-full bg-white px-4 pb-6 sm:max-w-sm">
          <div className="h-16 flex items-center">
            <button onClick={() => setMobileMenuOpen(false)}>
              <XMarkIcon className="w-6 h-6 text-gray-700" />
            </button>
          </div>
          <div className="space-y-2">
            <button
              onClick={() => setSelectedTab("overview")}
              className="block w-full text-left px-4 py-2 text-gray-900 hover:bg-gray-100">
              Overview
            </button>
            <button
              onClick={() => setSelectedTab("users")}
              className="block w-full text-left px-4 py-2 text-gray-900 hover:bg-gray-100">
              Users
            </button>
            <button
              onClick={() => setSelectedTab("activity")}
              className="block w-full text-left px-4 py-2 text-gray-900 hover:bg-gray-100">
              Activity Logs
            </button>
            <button
              onClick={() => setSelectedTab("trends")}
              className="block w-full text-left px-4 py-2 text-gray-900 hover:bg-gray-100">
              Trends
            </button>
            <button
              onClick={() => setSelectedTab("listings")}
              className="block w-full text-left px-4 py-2 text-gray-900 hover:bg-gray-100">
              Active Listings
            </button>
          </div>
        </DialogPanel>
      </Dialog>

      {/* Main Content */}
      <div className="flex-1 p-6">{renderTabContent()}</div>
    </div>
  );
};

export default AdminPage;
