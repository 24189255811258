import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAdminStats } from "../../store/thunks/adminThunk";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  UserGroupIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline"; // Import icons

const AdminStatistics = () => {
  const dispatch = useDispatch();

  const [stats, setStats] = useState([
    {
      name: "Total Users",
      value: "Loading...",
      icon: UserGroupIcon,
      change: "0%",
      changeType: "positive",
    },
    {
      name: "Active Users (Last 7 Days)",
      value: "Loading...",
      icon: ChartBarIcon,
      change: "0%",
      changeType: "positive",
    },
    {
      name: "Total Activity (Last 7 Days)",
      value: "Loading...",
      icon: ClipboardDocumentListIcon,
      change: "0%",
      changeType: "positive",
    },
  ]);

  useEffect(() => {
    dispatch(fetchAdminStats())
      .then((data) => {
        const {
          totalUsers,
          totalUsersWeekAgo,
          percentageChange,
          activeUsers,
          activeUsersWeekAgo,
          activeUsersChange,
          activityLogs,
          activityLogsWeekAgo,
          activityLogsChange,
        } = data.payload;

        setStats([
          {
            name: "Total Users (vs 7 days ago)",
            value: totalUsers
              ? `${totalUsers.toLocaleString()} (${
                  totalUsers - totalUsersWeekAgo > 0 ? "+" : ""
                }${(totalUsers - totalUsersWeekAgo).toLocaleString()})`
              : "N/A",
            icon: UserGroupIcon,
            change: `${percentageChange}%`,
            changeType: percentageChange >= 0 ? "positive" : "negative",
          },
          {
            name: "Active Users (Last 7 days)",
            value: activeUsers
              ? `${activeUsers.toLocaleString()} (${
                  activeUsers - activeUsersWeekAgo > 0 ? "+" : ""
                }${(activeUsers - activeUsersWeekAgo).toLocaleString()})`
              : "N/A",
            icon: ChartBarIcon,
            change: `${activeUsersChange}%`,
            changeType: activeUsersChange >= 0 ? "positive" : "negative",
          },
          {
            name: "Total Activity (Last 7 days)",
            value: activityLogs
              ? `${activityLogs.toLocaleString()} (${
                  activityLogs - activityLogsWeekAgo > 0 ? "+" : ""
                }${(activityLogs - activityLogsWeekAgo).toLocaleString()})`
              : "N/A",
            icon: ClipboardDocumentListIcon,
            change: `${activityLogsChange}%`,
            changeType: activityLogsChange >= 0 ? "positive" : "negative",
          },
        ]);
      })
      .catch((error) => {
        console.error("Error fetching admin stats:", error);
      });
  }, [dispatch]);

  return (
    <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="p-4 bg-white shadow-md flex items-center space-x-4 border border-gray-200">
          <stat.icon className="w-8 h-8 text-indigo-600" />
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-gray-900">
              {stat.value}
            </span>
            <span className="text-sm text-gray-600">{stat.name}</span>
          </div>
          <span
            className={`ml-auto text-sm font-medium ${
              stat.changeType === "positive" ? "text-green-600" : "text-red-600"
            }`}>
            {stat.changeType === "positive" ? (
              <ArrowTrendingUpIcon className="w-5 h-5 inline-block" />
            ) : (
              <ArrowTrendingDownIcon className="w-5 h-5 inline-block" />
            )}
            {stat.change}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AdminStatistics;
