import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchTrendingListings } from "../../store/thunks/adminThunk";
import LoadingSpinner from "../LoadingSpinner";
import { formatAddress } from "../../utils/formatAddress";

const AdminTrends = () => {
  const dispatch = useDispatch();
  const [trendingListings, setTrendingListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await dispatch(fetchTrendingListings()).unwrap();
        setTrendingListings(data);
      } catch (err) {
        setError(err.message || "Failed to fetch trending listings");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleListingClick = (listing) => {
    let url;
    const baseUrl = window.location.origin;

    if (listing.mlsNumber && listing.boardId) {
      // Construct property details URL
      url = `${baseUrl}/propertydetails/${listing.mlsNumber}/${listing.boardId}/`;
    } else {
      console.warn("No valid MLS details available for listing:", listing);
      return;
    }

    // Always open in a new tab
    window.open(url, "_blank");
  };

  return (
    <div className="p-4 bg-white">
      <h2 className="text-xl font-semibold text-gray-900">Trending Listings</h2>

      {loading && <LoadingSpinner />}
      {error && <p className="text-red-500">{error}</p>}

      {trendingListings.length > 0 ? (
        <ul className="mt-4 space-y-3">
          {trendingListings.map((listing, index) => (
            <li
              key={index}
              className="flex justify-between text-gray-700 text-sm border-b py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleListingClick(listing)}>
              <span>
                <strong>{listing.mlsNumber}</strong> (Board {listing.boardId}){" "}
                {listing.address ? formatAddress(listing.address) : ""}
              </span>
              <span className="text-gray-500">{listing.views} views</span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500 mt-4">No trending listings found.</p>
      )}
    </div>
  );
};

export default AdminTrends;
