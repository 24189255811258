import React, { useState, useEffect } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { fetchImageThunk } from "../../store/thunks/repliersThunk";
import LoadingSpinner from "../LoadingSpinner";
import noImage from "../../assets/noimage.png";

const ImageCarousel = ({ imageUrls = [], isGridTile = false }) => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  // Fetch the first image on mount
  useEffect(() => {
    if (imageUrls.length > 0) {
      fetchAndSetImage(0);
    }
  }, [imageUrls]);

  const fetchAndSetImage = async (index) => {
    setIsFetching(true);
    try {
      const image = await dispatch(fetchImageThunk(imageUrls[index])).unwrap();
      setCurrentImage(image);
    } catch (error) {
      // console.error("Error fetching image:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleNextImage = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling to parent
    const nextIndex = (currentIndex + 1) % imageUrls.length;
    setCurrentIndex(nextIndex);
    fetchAndSetImage(nextIndex);
  };

  const handlePrevImage = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling to parent
    const prevIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentIndex(prevIndex);
    fetchAndSetImage(prevIndex);
  };

  return (
    <div className="relative">
      {/* <div className="flex justify-center">
        <div className={`w-full ${isGridTile ? "max-w-md" : "w-full"}`}>
          <img
            src={`data:image/jpeg;base64,${currentImage || ""}`}
            alt="Loading"
            className="w-full h-full object-cover"
            style={{ aspectRatio: "4 / 3" }}
          />
        </div>
      </div> */}
      <div className="flex justify-center">
        <div className={`w-full ${isGridTile ? "max-w-md" : "w-full"}`}>
          {currentImage ? (
            <img
              src={`data:image/jpeg;base64,${currentImage}`}
              alt="Property"
              className="w-full h-full object-cover"
              style={{ aspectRatio: "4 / 3" }}
            />
          ) : (
            <img
              src={noImage}
              alt="No image available"
              className="w-full h-full object-cover"
              style={{ aspectRatio: "4 / 3" }}
            />
          )}
        </div>
      </div>

      {/* Navigation buttons */}
      {imageUrls.length > 1 && (
        <>
          <button
            onClick={handlePrevImage}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/20 text-[#0A2342] p-2 z-10 focus:outline-none hover:bg-white/40 transition-colors duration-200">
            <ArrowLeftIcon className="w-6 h-6" />
          </button>
          <button
            onClick={handleNextImage}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/20 text-[#0A2342] p-2 z-10 focus:outline-none hover:bg-white/40 transition-colors duration-200">
            <ArrowRightIcon className="w-6 h-6" />
          </button>
        </>
      )}

      {/* Loading spinner */}
      {isFetching && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-20">
          {/* <p className="text-white text-sm">Loading...</p> */}
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
