import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import * as maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
const DEFAULT_LOCATION = { longitude: -79.3871, latitude: 43.6426 }; // CN Tower, Toronto

const Map = forwardRef(({ locations, localData }, ref) => {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);
  const [mapStyle, setMapStyle] = useState("topo");
  const [activeSection, setActiveSection] = useState("schools");

  // Add debug logging
  useEffect(() => {
    console.log("Map component mounted, ref:", ref);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      console.log("Setting up map ref methods");
      return {
        flyTo: (options) => {
          if (mapInstance.current) {
            mapInstance.current.flyTo(options);
          }
        },
        setActiveSection: (section) => {
          console.log("Map setActiveSection called with:", section);
          setActiveSection(section);
        },
      };
    },
    []
  );

  // Initialize map
  useEffect(() => {
    if (locations.length === 0) return;

    const validLocation =
      locations[0].longitude && locations[0].latitude
        ? locations[0]
        : DEFAULT_LOCATION;

    const map = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`,
      center: [validLocation.longitude, validLocation.latitude],
      minZoom: 1,
      maxZoom: 18,
      pitch: 50,
      bearing: 0,
      zoom: 14,
    });

    mapInstance.current = map;

    // Add navigation control
    map.addControl(new maplibregl.NavigationControl(), "top-right");

    // Add markers for main property location
    locations.forEach((location) => {
      const markerLocation =
        location.longitude && location.latitude ? location : DEFAULT_LOCATION;

      const address = location.address || {};

      new maplibregl.Marker()
        .setLngLat([markerLocation.longitude, markerLocation.latitude])
        .setPopup(
          new maplibregl.Popup().setHTML(`
            <div class="text-sm text-gray-600">
              <p><strong>Address:</strong> ${address.streetNumber || ""} ${
            address.streetDirection || ""
          } ${address.streetName || ""} ${address.streetSuffix || ""}</p>
              <p><strong>Area:</strong> ${address.area || "N/A"}</p>
              <p><strong>City:</strong> ${address.city || "N/A"}</p>
              <p><strong>Postal Code:</strong> ${address.zip || "N/A"}</p>
              <p><strong>Major Intersection:</strong> ${
                address.majorIntersection || "N/A"
              }</p>
            </div>
          `)
        )
        .addTo(map);
    });

    // Fit the map to show all markers
    const bounds = new maplibregl.LngLatBounds();
    locations.forEach((location) => {
      const boundLocation =
        location.longitude && location.latitude ? location : DEFAULT_LOCATION;
      bounds.extend([boundLocation.longitude, boundLocation.latitude]);
    });
    map.fitBounds(bounds, { padding: 50 });

    // Clean up on unmount
    return () => map.remove();
  }, [locations, mapStyle]);

  // Handle activeSection changes
  useEffect(() => {
    if (!mapInstance.current || !activeSection || !localData) return;

    const markers = [];

    switch (activeSection) {
      case "schools":
        localData.schools?.forEach((school) => {
          if (school.point?.lng && school.point?.lat) {
            markers.push(
              new maplibregl.Marker({ color: "#4285F4" }) // Blue for schools
                .setLngLat([school.point.lng, school.point.lat])
                .setPopup(
                  new maplibregl.Popup().setHTML(`
                <div class="text-sm text-gray-600">
                  <p><strong>School:</strong> ${school.name}</p>
                  <p><strong>Type:</strong> ${
                    school.type?.join(", ") || "N/A"
                  }</p>
                </div>
              `)
                )
                .addTo(mapInstance.current)
            );
          }
        });
        break;
      case "parks":
        localData.parks?.forEach((park) => {
          if (park.point?.lng && park.point?.lat) {
            markers.push(
              new maplibregl.Marker({ color: "#34A853" }) // Green for parks
                .setLngLat([park.point.lng, park.point.lat])
                .setPopup(
                  new maplibregl.Popup().setHTML(`
                <div class="text-sm text-gray-600">
                  <p><strong>Park:</strong> ${park.name}</p>
                </div>
              `)
                )
                .addTo(mapInstance.current)
            );
          }
        });
        break;
      case "safety_places":
        localData.safety_places?.forEach((place) => {
          if (place.point?.lng && place.point?.lat) {
            markers.push(
              new maplibregl.Marker({ color: "#EA4335" }) // Red for safety places
                .setLngLat([place.point.lng, place.point.lat])
                .setPopup(
                  new maplibregl.Popup().setHTML(`
                <div class="text-sm text-gray-600">
                  <p><strong>${place.type}:</strong> ${place.name}</p>
                </div>
              `)
                )
                .addTo(mapInstance.current)
            );
          }
        });
        break;
      case "transit_stops":
        localData.transit_stops?.forEach((stop) => {
          if (stop.point?.lng && stop.point?.lat) {
            // Different colors for rail vs street transit
            const markerColor =
              stop.type === "rail-transit" ? "#c28f02" : "#FBBC05"; // Darker yellow for rail

            markers.push(
              new maplibregl.Marker({ color: markerColor })
                .setLngLat([stop.point.lng, stop.point.lat])
                .setPopup(
                  new maplibregl.Popup().setHTML(`
                <div class="text-sm text-gray-600">
                  <p><strong>${
                    stop.type === "rail-transit"
                      ? "Rail Station"
                      : "Street Transit Stop"
                  }:</strong> ${stop.name}</p>
                </div>
              `)
                )
                .addTo(mapInstance.current)
            );
          }
        });
        break;
        default:
          console.warn("Unhandled activeSection:", activeSection);
          break;
    }

    // Cleanup function to remove markers when section changes
    return () => {
      markers.forEach((marker) => marker.remove());
    };
  }, [activeSection, localData]);

  return (
    <div className="relative">
      {/* Map container */}
      <div ref={mapContainer} style={{ width: "100%", height: "400px" }} />

      {/* Map style buttons */}
      <div className="absolute overflow-x-auto top-2 left-2 bg-white p-1 shadow-md flex flex-wrap space-x-1 max-w-full">
        {["topo", "streets", "basic", "bright", "pastel", "hybrid"].map(
          (style) => (
            <button
              key={style}
              onClick={() => setMapStyle(style)}
              className={`text-xs px-1 py-0.5 ${
                mapStyle === style
                  ? "bg-[#0A2342] text-white"
                  : "bg-white text-[#0A2342] border border-[#0A2342]"
              }`}
            >
              {style.charAt(0).toUpperCase() + style.slice(1)}
            </button>
          )
        )}
      </div>
    </div>
  );
});

export default Map;
