import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOnboardingThunk,
  submitOnboardingThunk,
  updateOnboardingThunk,
} from "../store/thunks/onboardingThunk";
import { fetchAggregatesThunk } from "../store/thunks/repliersThunk";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { motion } from "framer-motion";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import ProgressBar from "../components/ProgressBar";
import OnboardingQuestions from "../components/onboardingQuestions";
import * as Slider from "@radix-ui/react-slider";

export default function OnboardingPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [answers, setAnswers] = useState({
    minPrice: 50000,
    maxPrice: 5000000,
    topAmenities: [],
  });
  const [submitError, setSubmitError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, user } = useSelector((state) => state.auth);
  const { aggregates } = useSelector((state) => state.repliers);

  console.log(user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAggregatesThunk());

        const onboardingData = await dispatch(
          fetchOnboardingThunk({ token, userId: user._id })
        ).unwrap();

        if (onboardingData?.answers) {
          setAnswers((prev) => ({
            ...prev,
            ...onboardingData.answers, // Merge fetched data
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, token, user._id]); // Add token and user._id as dependencies

  const handleSkipOnboarding = () => {
    navigate("/dashboard");
  };

  const totalSteps = OnboardingQuestions.length;
  const progress = ((currentStep + 1) / totalSteps) * 100;

  const isCurrentAnswerValid = () => {
    const currentQuestion = OnboardingQuestions[currentStep];
    const answer = answers[currentQuestion.key];

    if (currentQuestion.type === "slider") {
      if (answers.minPrice >= answers.maxPrice) {
        setValidationError(
          `Minimum price (${answers.minPrice.toLocaleString()}) should be less than maximum price (${answers.maxPrice.toLocaleString()}).`
        );
        return false;
      }
      setValidationError("");
      return true;
    }

    if (currentQuestion.type === "multi-select" && Array.isArray(answer)) {
      if (answer.length === 0) {
        setValidationError(`Question requires an answer.`);
        return false;
      }
      if (answer.length > 3) {
        setValidationError(`You can only select up to 3 options.`);
        return false;
      }
    } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
      setValidationError(`Question requires an answer.`);
      return false;
    }

    setValidationError("");
    return true;
  };
  const handleNext = () => {
    if (!isCurrentAnswerValid()) return;
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAnswerChange = (value, key, type = "radio") => {
    if (type === "multi-select" || type === "multi-select-dropdown") {
      const selected = answers[key] || [];
      if (selected.includes(value)) {
        setAnswers({
          ...answers,
          [key]: selected.filter((item) => item !== value),
        });
      } else if (selected.length < 5) {
        setAnswers({
          ...answers,
          [key]: [...selected, value],
        });
      }
    } else if (type === "text") {
      setAnswers({
        ...answers,
        [key]: value,
      });
    } else if (type === "radio") {
      setAnswers({
        ...answers,
        [key]: value,
      });
    }
  };

  const handleSliderChange = (value) => {
    setAnswers({
      ...answers,
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  const handleRankingChange = (result) => {
    if (!result.destination) return;

    const items = Array.from(answers.ranking || currentQuestion.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      ranking: items,
    }));
  };

  const handleSubmit = async () => {
    console.log("Current answers:", answers);

    // Validate that all required fields are filled
    for (const question of OnboardingQuestions) {
      const answer = answers[question.key];
      if (
        !answer ||
        (Array.isArray(answer) && answer.length === 0) ||
        (typeof answer === "number" && answer === 0)
      ) {
        if (question.key === "priceRange") {
          if (
            answers.minPrice === undefined ||
            answers.maxPrice === undefined
          ) {
            setValidationError(
              `Please complete the question: "${question.question}"`
            );
            return;
          }
        } else {
          setValidationError(
            `Please complete the question: "${question.question}"`
          );
          return;
        }
      }
    }

    // Ensure city and price fields are included
    const payload = {
      ...answers,
      city: answers.city || [],
      minPrice: answers.minPrice || 50000, // Default value if not set
      maxPrice: answers.maxPrice || 5000000, // Default value if not set
    };

    setSubmitError("");

    try {
      if (user.onboardingComplete) {
        await dispatch(updateOnboardingThunk(payload)).unwrap();
        navigate("/loading", {
          state: {
            message: "Onboarding updated.",
            message2: "Updating your dashboard preferences",
            duration: 5000,
            redirectTo: "/dashboard",
          },
        });
      } else {
        await dispatch(submitOnboardingThunk(payload)).unwrap();
        navigate("/loading", {
          state: {
            message: "Onboarding saved.",
            message2: "Creating your custom dashboard",
            duration: 5000,
            redirectTo: "/dashboard",
          },
        });
      }
    } catch (error) {
      if (error?.type === "alreadyCompleted") {
        setSubmitError(
          "Onboarding already completed. You can update your preferences."
        );
      } else {
        setSubmitError("Onboarding submission failed. Please try again.");
      }
    }
  };

  const currentQuestion = OnboardingQuestions[currentStep];
  const { type } = currentQuestion;

  const onboardingTexts = OnboardingQuestions.map((q) => q.name);

  useEffect(() => {
    if (
      aggregates?.aggregates?.address &&
      aggregates.aggregates.address[currentQuestion.key]
    ) {
      const options = Object.keys(
        aggregates.aggregates.address[currentQuestion.key]
      );
      // console.log("Available options:", options);
      setFilteredOptions(options); // Now this is an array of keys
    }
  }, [aggregates, currentQuestion.key]);

  useEffect(() => {
    if (type === "ranking" && !answers.ranking) {
      setAnswers((prev) => ({ ...prev, ranking: currentQuestion.options }));
    }
  }, [currentStep, type, answers.ranking, currentQuestion.options]);

  if (!hasStarted) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-center">
        <h1 className="text-3xl font-bold text-[#0A2342] mb-8">
          {user.onboardingComplete
            ? `Welcome back, ${user.firstName} ${user.lastName}`
            : `Welcome to Synergy AI, ${user.firstName} ${user.lastName}`}
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          {user.onboardingComplete
            ? "To update your preferences, please complete the questionnaire."
            : "Please complete the following onboarding questionnaire to personalize your search results."}
        </p>
        {user.onboardingComplete && (
          <p className="text-lg text-gray-700 mb-8">
            To abort your changes, you can exit the questionnaire at any time.
          </p>
        )}
        <motion.button
          onClick={() => setHasStarted(true)}
          className="bg-[#0A2342] text-white py-2 px-6"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}>
          Begin
        </motion.button>
        {/* {user.onboardingComplete && (
          <motion.button
            onClick={() => navigate("/dashboard")}
            className="bg-gray-200 text-[#0A2342] py-2 px-6 mt-4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}>
            Skip Questionnaire
          </motion.button>
        )} */}
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Skip onboarding button */}
      {user.onboardingComplete && (
        <motion.button
          onClick={handleSkipOnboarding}
          className="absolute top-4 right-4 bg-gray-200 text-[#0A2342] py-2 px-4"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}>
          Exit Questionnaire
        </motion.button>
      )}

      {/* Onboarding questions */}
      <motion.div
        className="w-full max-w-2xl bg-white p-8 shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <ProgressBar
          topText={`Step ${currentStep + 1} of ${totalSteps}: ${
            onboardingTexts[currentStep]
          }`}
          progress={progress}
        />

        <h2 className="text-2xl font-bold mb-6 text-center text-[#0A2342]">
          {currentQuestion.question}
        </h2>
        <div className="space-y-4">
          {type === "ranking" && (
            <DragDropContext onDragEnd={handleRankingChange}>
              <Droppable droppableId="ranking">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-2 bg-white p-4 border border-gray-300">
                    {(answers.ranking || currentQuestion.options).map(
                      (option, index) => (
                        <Draggable
                          key={option}
                          draggableId={option}
                          index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`p-3 bg-gray-100 border border-[#0A2342] text-[#0A2342] flex items-center justify-between ${
                                snapshot.isDragging ? "shadow-lg" : ""
                              }`}>
                              <span>{option}</span>
                              <EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {type === "radio" && (
            <div className="space-y-4">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="flex items-center space-x-3">
                  <input
                    type="radio"
                    name={currentQuestion.key}
                    value={option}
                    onChange={() =>
                      handleAnswerChange(option, currentQuestion.key, "radio")
                    }
                    checked={answers[currentQuestion.key] === option}
                    className="form-radio h-5 w-5 text-[#0A2342] accent-[#0A2342]"
                  />
                  <span className="text-[#0A2342]">{option}</span>
                </label>
              ))}
            </div>
          )}

          {type === "slider" && (
            <div className="space-y-4">
              <label htmlFor="priceRange" className="block text-sm font-medium">
                Price Range: ${answers.minPrice.toLocaleString()} - $
                {answers.maxPrice.toLocaleString()}
              </label>
              <Slider.Root
                className="relative flex items-center select-none touch-none w-full h-5"
                value={[answers.minPrice, answers.maxPrice]}
                onValueChange={handleSliderChange}
                min={50000}
                max={5000000}
                step={50000}>
                <Slider.Track className="bg-gray-300 relative grow h-[8px]">
                  <Slider.Range className="absolute bg-[#0A2342] h-full" />
                </Slider.Track>
                <Slider.Thumb className="block w-6 h-6 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                <Slider.Thumb className="block w-6 h-6 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
              </Slider.Root>
            </div>
          )}

          {type === "multi-select-dropdown" && (
            <div className="relative">
              <div className="border border-[#0A2342] bg-white shadow-md max-h-60 overflow-y-auto">
                {/* Input for search */}
                <input
                  type="text"
                  className="w-full px-4 py-2 border-b focus:outline-none"
                  placeholder={`Search ${currentQuestion.name || "options"}`}
                  onChange={(e) => {
                    const searchTerm = e.target.value.toLowerCase();
                    const options = Object.keys(
                      aggregates?.aggregates?.address?.[currentQuestion.key] ||
                        {}
                    );
                    const filtered = options.filter((option) =>
                      option.toLowerCase().includes(searchTerm)
                    );
                    setFilteredOptions(filtered);
                  }}
                />
                {/* Dropdown Options */}
                <div>
                  {(filteredOptions.length > 0
                    ? filteredOptions
                    : Object.keys(
                        aggregates?.aggregates?.address?.[
                          currentQuestion.key
                        ] || {}
                      )
                  ).map((option) => (
                    <button
                      key={option}
                      onClick={() =>
                        handleAnswerChange(option, currentQuestion.key, type)
                      }
                      className={`block w-full text-left px-4 py-2 border-b last:border-b-0 ${
                        answers[currentQuestion.key]?.includes(option)
                          ? "bg-[#0A2342] text-white"
                          : "hover:bg-gray-200"
                      }`}>
                      {option}
                    </button>
                  ))}
                </div>
              </div>

              {/* Selected Options */}
              <div className="flex flex-wrap gap-2 mt-2">
                {answers[currentQuestion.key]?.map((selected) => (
                  <span
                    key={selected}
                    className="bg-[#0A2342] text-white px-3 py-1 rounded-full">
                    {selected}
                    <button
                      className="ml-2 text-xs text-red-500"
                      onClick={() =>
                        handleAnswerChange(selected, currentQuestion.key, type)
                      }>
                      ✕
                    </button>
                  </span>
                ))}
              </div>
            </div>
          )}

          {type === "multi-select" && (
            <div className="space-y-4">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name={currentQuestion.key}
                    value={option}
                    onChange={() =>
                      handleAnswerChange(
                        option,
                        currentQuestion.key,
                        "multi-select"
                      )
                    }
                    checked={answers[currentQuestion.key]?.includes(option)}
                    className="form-checkbox h-5 w-5 text-[#0A2342] accent-[#0A2342] rounded-none"
                  />
                  <span className="text-[#0A2342]">{option}</span>
                </label>
              ))}
            </div>
          )}

          {type === "text" && (
            <div className="space-y-4">
              <input
                type="text"
                name={currentQuestion.key}
                value={answers[currentQuestion.key] || ""}
                onChange={handleAnswerChange}
                className="form-input w-full border border-[#0A2342] p-2 px-3 text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342]"
                placeholder="Separate multiple areas with commas"
              />
            </div>
          )}

          {!type && (
            <div className="space-y-4">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="flex items-center space-x-3">
                  <input
                    type="radio"
                    name={currentQuestion.key}
                    value={option}
                    onChange={handleAnswerChange}
                    checked={answers[currentQuestion.key] === option}
                    className="form-radio h-5 w-5 text-[#0A2342] accent-[#0A2342]"
                  />
                  <span className="text-[#0A2342]">{option}</span>
                </label>
              ))}
            </div>
          )}
        </div>

        {validationError && (
          <p className="text-red-600 text-sm text-center mt-4">
            {validationError}
          </p>
        )}

        {/* Navigation buttons */}
        <div className="flex justify-between mt-8">
          <motion.button
            onClick={handleBack}
            className="bg-gray-300 text-[#0A2342] py-2 px-6 disabled:opacity-50"
            disabled={currentStep === 0}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}>
            Back
          </motion.button>
          {currentStep < OnboardingQuestions.length - 1 ? (
            <motion.button
              onClick={handleNext}
              className="bg-[#0A2342] text-white py-2 px-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Next
            </motion.button>
          ) : (
            <motion.button
              onClick={handleSubmit}
              className="bg-[#0A2342] text-white py-2 px-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              {user.onboardingComplete
                ? "Update Onboarding"
                : "Submit Onboarding"}
            </motion.button>
          )}
        </div>

        {/* Submit error */}
        {submitError && (
          <div className="text-center">
            <p className="text-red-600 text-sm text-center mt-4">
              {submitError}
            </p>
            <button
              onClick={handleSkipOnboarding}
              className="bg-gray-200 text-[#0A2342] py-2 px-4 mt-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Exit Questionnaire
            </button>
          </div>
        )}

        {/* Submit success */}
        {submitSuccess && (
          <div className="text-green-600 text-center mt-6">
            <p className="text-lg font-semibold">
              Onboarding completed successfully!
            </p>
            <motion.button
              onClick={() => navigate("/dashboard")}
              className="mt-4 bg-[#0A2342] text-white py-2 px-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Navigate to Dashboard
            </motion.button>
          </div>
        )}
      </motion.div>
    </div>
  );
}
