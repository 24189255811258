import React from "react";
import AdminStatistics from "./AdminStatistics";
import AdminRecentActivity from "./AdminRecentActivity";

const AdminOverview = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold text-gray-900">Admin Overview</h1>
      <p className="text-gray-600 mt-2">
        A quick glance at key metrics and recent activity.
      </p>

      {/* Stats Section */}
      <AdminStatistics />

      {/* Recent Activity Section */}
      <AdminRecentActivity />
    </div>
  );
};

export default AdminOverview;
