import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";
import { sendPasswordResetEmailThunk } from "../store/thunks/emailThunk";
import { resetEmailState } from "../store/slices/emailSlice";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const { loading, error, successMessage } = useSelector(
    (state) => state.email
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("reset email data sent to thunk", email);

    try {
      await dispatch(sendPasswordResetEmailThunk(email)).unwrap();
    } catch (error) {
      console.error("Error sending reset email:", error);
    }
  };

  // Reset email state when unmounting
  React.useEffect(() => {
    return () => {
      dispatch(resetEmailState());
    };
  }, [dispatch]);

  if (successMessage) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <motion.div
          className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}>
          <div className="text-center">
            <h2 className="text-2xl font-bold text-[#0A2342] mb-4">
              Password Reset Email Sent
            </h2>
            <p className="text-gray-700 mb-4">
              If an account is associated with <strong>{email}</strong>, you
              will receive an email with instructions to reset your password.
            </p>
            <p className="text-gray-600 mb-4">
              Please check your inbox and spam folder. If you don’t receive the
              email within a few minutes, try requesting a reset again.
            </p>
            <motion.button
              onClick={() => navigate("/login")}
              className="text-sm text-[#0A2342] hover:text-[#1C4B82] transition duration-200"
              whileHover={{ scale: 1.05 }}>
              Return to Login
            </motion.button>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div>
          <img
            className="mx-auto h-12 w-auto cursor-pointer"
            src={logo}
            alt="Synergy Real Estate Logo"
            width={48}
            height={48}
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-[#0A2342]">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Enter your email address and we'll send you a link to reset your
            password.
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {error && <p className="text-center text-sm text-red-600">{error}</p>}

          <div>
            <motion.button
              type="submit"
              disabled={loading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342] disabled:opacity-50"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              {loading ? "Sending..." : "Send reset link"}
            </motion.button>
          </div>
        </form>

        <div className="text-center">
          <motion.button
            onClick={() => navigate("/login")}
            className="text-sm text-[#0A2342] hover:text-[#1C4B82]"
            whileHover={{ scale: 1.05 }}>
            Back to login
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
}
