// slices/emailSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { sendContactEmailThunk, sendPasswordResetEmailThunk, sendPropertyContactEmailThunk } from "../thunks/emailThunk";

const initialState = {
  loading: false,
  error: null,
  successMessage: null,
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    resetEmailState: (state) => {
      Object.assign(state, initialState);
    },
    clearEmailError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendContactEmailThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(sendContactEmailThunk.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = "Contact email sent.";
      })
      .addCase(sendContactEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      
      .addCase(sendPasswordResetEmailThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(sendPasswordResetEmailThunk.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = "Password reset email sent.";
      })
      .addCase(sendPasswordResetEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      
      .addCase(sendPropertyContactEmailThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(sendPropertyContactEmailThunk.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = "Property inquiry email sent.";
      })
      .addCase(sendPropertyContactEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { resetEmailState, clearEmailError } = emailSlice.actions;
export default emailSlice.reducer;
