import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const CashFlowCalculator = ({ property, monthlyPayment }) => {
  const dispatch = useDispatch();
  const location = useLocation(); // To get the current page URL

  const [monthlyTax, setMonthlyTax] = useState(
    (parseFloat(property.taxes.annualAmount) / 12)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
  const [maintenanceCost, setMaintenanceCost] = useState(
    property.condominium?.fees?.maintenance || "" // Initialize with condo fees if they exist
  );
  const [rentalIncome, setRentalIncome] = useState("");
  const [mortgagePayment, setMortgagePayment] = useState(monthlyPayment);
  const [utilitiesCost, setUtilitiesCost] = useState("");

  // Update mortgagePayment whenever the monthlyPayment prop changes
  useEffect(() => {
    setMortgagePayment(monthlyPayment);
  }, [monthlyPayment]);

  const calculateCashFlow = () => {
    const totalExpenses =
      (parseFloat(monthlyTax) || 0) +
      (parseFloat(maintenanceCost) || 0) +
      (parseFloat(mortgagePayment) || 0) +
      (parseFloat(utilitiesCost) || 0); // Include utilities cost
    return (parseFloat(rentalIncome) || 0) - totalExpenses;
  };

  const calculateTotalMonthlyCost = () => {
    return (
      (parseFloat(monthlyTax) || 0) +
      (parseFloat(maintenanceCost) || 0) +
      (parseFloat(utilitiesCost) || 0) +
      (parseFloat(mortgagePayment) || 0)
    ).toFixed(2); // Format to 2 decimal places
  };

  const resetFields = () => {
    setMonthlyTax(""); // Reset to empty string
    setMaintenanceCost(""); // Reset to empty string
    setRentalIncome(""); // Reset to empty string
    setUtilitiesCost(""); // Reset to empty string
    setMortgagePayment(monthlyPayment); // Reset to prop value
  };

  // Data for the pie chart
  const cashFlowValue = calculateCashFlow();
  const cashFlowData = [
    {
      name: "Rental Income",
      value: Math.max(parseFloat(rentalIncome) || 0, 0),
    },
    {
      name: "Mortgage & Utilities",
      value:
        (parseFloat(mortgagePayment) || 0) + (parseFloat(utilitiesCost) || 0),
    },
    {
      name: "Maintenance Fees",
      value: (parseFloat(monthlyTax) || 0) + (parseFloat(maintenanceCost) || 0),
    },
  ];

  const COLORS = ["#0A2342", "#E5B13A", "#F4C668"]; // Dark blue, gold, lighter gold

  // Add calculation for maintenance percentage
  const calculateMaintenancePercentage = () => {
    const maintenanceAmount = parseFloat(maintenanceCost) || 0;
    const totalMonthly = calculateTotalMonthlyCost();
    return totalMonthly > 0
      ? ((maintenanceAmount / totalMonthly) * 100).toFixed(1)
      : 0;
  };

  // Log the activity when cash flow is calculated, but only when all fields are filled
  const logActivity = () => {
    if (
      monthlyTax &&
      maintenanceCost &&
      rentalIncome &&
      mortgagePayment &&
      utilitiesCost
    ) {
      dispatch(
        sendUserActivityLog({
          eventType: "CASH_FLOW_CALCULATION",
          metadata: {
            monthlyTax,
            maintenanceCost,
            rentalIncome,
            mortgagePayment,
            utilitiesCost,
            totalCashFlow: cashFlowValue.toFixed(2),
            mlsNumber: property.mlsNumber,
            boardId: property.boardId,
          },
          address: property.address,
        })
      );
    }
  };

  useEffect(() => {
    // Log the activity when all fields are filled and recalculation occurs
    logActivity();
  }, [
    monthlyTax,
    maintenanceCost,
    rentalIncome,
    mortgagePayment,
    utilitiesCost,
  ]);

  return (
    <div className="border border-[#0A2342] p-4">
      <h3 className="text-lg font-semibold text-[#0A2342]">
        Cash Flow Calculator
      </h3>
      <p className="block text-sm font-medium text-gray-700 pb-2">
        All values are in CAD
      </p>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 pr-4 space-y-4">
          {/* Monthly Mortgage Payment Field */}
          <div>
            <label
              htmlFor="mortgagePayment"
              className="block text-sm font-medium text-gray-700">
              Monthly Mortgage Payment
              {maintenanceCost && (
                <span className="block text-sm text-gray-500">
                  Includes maintenance fees: $
                  {parseFloat(maintenanceCost).toFixed(2)} (
                  {calculateMaintenancePercentage()}% of monthly payment)
                </span>
              )}
            </label>
            <input
              type="number" // Ensure this is set to number
              id="mortgagePayment"
              className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
              value={mortgagePayment.toFixed(2)} // Format to 2 decimal places
              readOnly // Optional: make it read-only if you don't want user input
              onChange={(e) => setMortgagePayment(Number(e.target.value))} // Handle changes if needed
            />
          </div>

          {/* Row 1 */}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            {/* Monthly Utilities Costs Field */}
            <div className="w-full">
              <label
                htmlFor="utilitiesCost"
                className="block text-sm font-medium text-gray-700">
                Monthly Utilities Costs
              </label>
              <input
                type="number"
                id="utilitiesCost"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                value={utilitiesCost}
                onChange={(e) => setUtilitiesCost(e.target.value)}
              />
            </div>

            {/* Monthly Property Tax Field */}
            <div className="w-full">
              <label
                htmlFor="monthlyTax"
                className="block text-sm font-medium text-gray-700">
                Monthly Property Tax
              </label>
              <input
                type="number"
                id="monthlyTax"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                value={monthlyTax}
                onChange={(e) => setMonthlyTax(e.target.value)}
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            {/* Maintenance Cost Field */}
            <div className="w-full">
              <label
                htmlFor="maintenanceCost"
                className="block text-sm font-medium text-gray-700">
                Maintenance Fees
              </label>
              <input
                type="number"
                id="maintenanceCost"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                value={maintenanceCost}
                onChange={(e) => setMaintenanceCost(e.target.value)}
              />
            </div>

            {/* Rental Income Field */}
            <div className="w-full">
              <label
                htmlFor="rentalIncome"
                className="block text-sm font-medium text-gray-700">
                Rental Income
              </label>
              <input
                type="number"
                id="rentalIncome"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                value={rentalIncome}
                onChange={(e) => setRentalIncome(e.target.value)}
              />
            </div>
          </div>

          {/* Total Monthly Cost Field */}
          <div>
            <label
              htmlFor="totalMonthlyCost"
              className="block text-sm font-medium text-gray-700">
              Total Monthly Cost
            </label>
            <input
              type="number"
              id="totalMonthlyCost"
              className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
              value={calculateTotalMonthlyCost()} // Calculate total monthly cost
              readOnly // Make it read-only
            />
          </div>

          <button
            onClick={resetFields}
            className="bg-[#0A2342] text-white py-2 px-4 mt-4 hover:bg-opacity-90 transition-colors duration-200">
            Reset Fields
          </button>
        </div>

        <div className="w-full md:w-1/2 pl-4 flex flex-col justify-center items-center">
          <div className="text-center mb-4">
            <span className="text-lg font-semibold text-[#0A2342]">
              Cash Flow:{" "}
            </span>
            <span className="text-2xl font-semibold text-[#0A2342]">
              $
              {calculateCashFlow()
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </div>

          {/* Pie Chart for Cash Flow */}
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={cashFlowData}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value">
                {cashFlowData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) =>
                  `$${new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(value)}`
                }
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CashFlowCalculator;
