import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dataReducer from "./slices/dataSlice";
import authReducer from "./slices/authSlice";
import onboardingReducer from "./slices/onboardingSlice";
import repliersReducer from "./slices/repliersSlice";
import repliersFavoritesReducer from "./slices/repliersFavoritesSlice";
import repliersNlpReducer from "./slices/repliersNlpSlice";
import favoritesReducer from "./slices/favoritesSlice";
import emailReducer from "./slices/emailSlice";
import recommenderReducer from "./slices/recommenderSlice";
import userActivityLogReducer from "./slices/userActivityLogSlice";
import adminReducer from "./slices/adminSlice";

const persistConfig = {
  key: "propulsionai",
  storage,
  whitelist: ["auth", "userPreferences", "favorites"],
};

const rootReducer = combineReducers({
  data: dataReducer,
  auth: authReducer,
  onboarding: onboardingReducer,
  repliers: repliersReducer,
  repliersFavorites: repliersFavoritesReducer,
  repliersNlp: repliersNlpReducer,
  favorites: favoritesReducer,
  email: emailReducer,
  recommender: recommenderReducer,
  userActivityLog: userActivityLogReducer,
  admin: adminReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/dataThunk";
export * from "./thunks/authThunk";
export * from "./thunks/onboardingThunk";
export * from "./thunks/repliersThunk";
export * from "./thunks/repliersFavoritesThunk";
export * from "./thunks/repliersNlpThunk";
export * from "./thunks/recommenderThunk";
export * from "./thunks/userActivityLogThunk";
export * from "./thunks/adminThunk";
