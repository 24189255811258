// adminThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

// Thunk to check if the current user is an admin
export const checkAdminStatus = createAsyncThunk(
  "admin/checkAdminStatus",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin", config);

      return response.data.isAdmin;
    } catch (error) {
      console.error("Error checking admin status:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to verify admin status"
      );
    }
  }
);

export const fetchAdminStats = createAsyncThunk(
  "admin/fetchAdminStats",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/stats", config);

      return response.data;
    } catch (error) {
      console.error("Error fetching admin stats:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch admin stats"
      );
    }
  }
);

export const fetchRecentActivity = createAsyncThunk(
  "admin/fetchRecentActivity",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/recent-activity", config);

      return response.data;
    } catch (error) {
      console.error("Error fetching recent activity:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch recent activity"
      );
    }
  }
);

export const fetchTrendingListings = createAsyncThunk(
  "admin/fetchTrendingListings",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/trending-listings", config);
      
      return response.data;
    } catch (error) {
      console.error("Error fetching trending listings:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch trending listings"
      );
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "admin/fetchUsers",
  async (
    { page = 1, limit = 10, sortBy = "createdAt", sortOrder = "desc" },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/users", {
        params: { page, limit, sortBy, sortOrder },
        headers: config.headers,
      });

      return {
        users: response.data.users,
        totalUsers: response.data.totalUsers,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage,
      };
    } catch (error) {
      console.error("Error fetching users:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch users"
      );
    }
  }
);

// New Thunk to fetch user preferences based on user ID
export const fetchUserPreferenceById = createAsyncThunk(
  "admin/fetchUserPreferenceById",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(`/v1/api/admin/onboarding/${userId}`, {
        headers: config.headers,
      });

      return response.data; // Return the fetched onboarding data
    } catch (error) {
      console.error("Error fetching user preferences:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user preferences"
      );
    }
  }
);

// Thunk to fetch activity logs and device data
export const fetchActivityLogs = createAsyncThunk(
  "admin/fetchActivityLogs",
  async ({ userId, hours }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get("/v1/api/admin/activity-logs", {
        params: { userId, hours },
        headers: config.headers,
      });

      return {
        chartData: response.data.chartData, // Time-based data
        deviceProportions: response.data.deviceProportions, // Device breakdown
      };
    } catch (error) {
      console.error("Error fetching activity logs:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch activity logs"
      );
    }
  }
);
