import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { fetchNlpPropertiesThunk } from "../../store/thunks/repliersNlpThunk";
import { sendUserActivityLog } from "../../store/thunks/userActivityLogThunk";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../LoadingSpinner";

const NLPSearchBar = () => {
  const [inputValue, setInputValue] = useState("");
  const [nlpStatus, setNlpStatus] = useState("idle");
  const [listings, setListings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const handleSearch = async () => {
    if (inputValue.trim()) {
      setNlpStatus("loading");
      try {
        const response = await dispatch(
          fetchNlpPropertiesThunk(inputValue)
        ).unwrap();
        console.log("NLP Search Response:", response);
        setListings(response.listings.listings || []);
        setCurrentPage(1);
        setNlpStatus("success");

        dispatch(
          sendUserActivityLog({
            eventType: "NLP_SEARCH_QUERY",
            metadata: {
              searchQuery: inputValue,
            },
          })
        );
      } catch (error) {
        console.error("Search error:", error);
        setNlpStatus("error");
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="relative bg-[#f5f5f5] border border-[#0A2342] shadow-md flex items-center w-full max-w-3xl mx-auto">
      <input
        type="text"
        placeholder="Search using natural language (e.g. find me a 2 bed 2 bath...)"
        className="flex-grow px-3 py-1 text-[#0A2342] focus:outline-none"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        onFocus={() => setNlpStatus("idle")}
      />
      <button className="p-2 bg-[#0A2342] text-white" onClick={handleSearch}>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <MagnifyingGlassIcon className="h-5 w-5" />
        </motion.div>
      </button>

      {nlpStatus === "loading" && (
        <p className="absolute top-full pt-4 left-0 w-full text-center text-gray-500">
          Searching...
          <LoadingSpinner />
        </p>
      )}
      {nlpStatus === "error" && (
        <p className="absolute top-full left-0 w-full text-center text-red-500">
          Failed to fetch results. Please try again.
        </p>
      )}
    </div>
  );
};

export default NLPSearchBar;
